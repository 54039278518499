import React, { useState, useEffect } from "react";
import styles from "../EditDiv/EditDiv.module.css";
import buttonStyles from "../YesNoButtonsCSS/YesNoButtons.module.css";
import BlueLogo from "../../assets/BlueLogo.png";
import { Box, Grid, TextField, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import CommentBox from "../CommentBox/CommentBox";
import DropDownV2 from "../DropDownBox/DropdownV2";
import Dropdown from "../DropDownBox/Dropdown";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import textfeildStyle from "../../GlobalStyles/styles";
import { getClient, getBillPayer } from "../../redux/slices/opportunitiesdata";

const OppForm = ({ onSubmit, onClose }) => {
  const dispatch = useDispatch();
  const { configuration, billPayer, client, newClient } = useSelector(
    (state) => state.opportunities
  );

  const [jobTypes, setJobTypes] = useState([]);
  const [oppSources, setOppSources] = useState([]);
  const [isaAssignments, setIsaAssignments] = useState([]);

  // Initialize state for each form field
  const [values, setValues] = useState({
    address: "",
    city: "",
    postcode: "",
    architectCompanyName: "",
    billPayerName: "",
    architectName: "",
    issueEmail: "",
    sourceOfOppName: "",
    isaAssignmentName: "",
    jobTypeName: "",
    comment: "",
  });

  useEffect(() => {
    if (configuration) {
      const formattedJobTypes = configuration.jobTypes.map((jobType) => ({
        value: jobType.id,
        label: jobType.name,
      }));
      setJobTypes(formattedJobTypes);

      const formattedOppSource = configuration.sourceOfOpportunities.map(
        (oppSource) => ({
          value: oppSource.id,
          label: oppSource.name,
        })
      );
      setOppSources(formattedOppSource);

      const formattedIsaAssignment = configuration.isaAssignments.map(
        (assignee) => ({
          value: assignee.id,
          label: assignee.name,
        })
      );
      setIsaAssignments(formattedIsaAssignment);

    }
  }, [configuration]);

  const handleSearchChange = (newSearchTerm, client_type) => {
    if (client_type === "professional") {
      dispatch(getClient(newSearchTerm, client_type));
    } else {
      dispatch(getBillPayer(newSearchTerm, client_type));
    }
  };

  const handleFormClick = (e) => {
    e.stopPropagation(); // Stops the click event from bubbling up to the parent div
  };

  const initialValues = {
    address: "",
    city: "",
    postcode: "",
    billPayerName: "",
    architectCompanyName: "",
    clientID: "",
    billPayerID: "",
    architectName: "",
    issueEmail: "",
    sourceOfOppID: "",
    sourceOfOppName: "",
    isaAssignmentID: "",
    isaAssignmentName: "",
    jobTypeID: "",
    jobTypeName: "",
    comment: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: OppSchema,
    onSubmit: async (values, { resetForm }) => {
      onSubmit(values);
      onClose();
      console.log(values);
    },
  });
  const { getFieldProps, touched, errors, handleSubmit } = formik;
  return (
    <div className={styles.backGround}>
      <div
        className={styles.form}
        style={{ width: "500px" }}
        onClick={handleFormClick}
      >
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>{`Fee Proposal Form`}</div>

        <FormikProvider value={formik} handleSubmit={handleSubmit}>
          <Form autoComplete="off" noValidate style={{ width: "100%" }}>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Address line 1: </p>
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.address}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("address", e?.target?.value)
                  }
                  placeholder="Address line 1"
                  sx={textfeildStyle}
                  error={Boolean(touched.address && errors.address)}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>City: </p>
                {/* {"Address" + ":"} */}
                {/* {subLabel && (
                  <div className={styles.dataSubTitle}>{subLabel}</div>
                )} */}
                {/* </div> */}
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.city}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("city", e?.target?.value)
                  }
                  placeholder="City"
                  sx={textfeildStyle}
                  error={Boolean(touched.city && errors.city)}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Post Code: </p>
                {/* {"Address" + ":"} */}
                {/* {subLabel && (
                  <div className={styles.dataSubTitle}>{subLabel}</div>
                )} */}
                {/* </div> */}
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.postcode}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("postcode", e?.target?.value)
                  }
                  placeholder="Post Code"
                  sx={textfeildStyle}
                  error={Boolean(touched.postcode && errors.postcode)}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2WithSub}>Bill Payer Name: </p>
                {/* {"Address" + ":"} */}

                <div
                  className={styles.dataSubTitle}
                  style={{ marginBottom: 10 }}
                >
                  {"c/o if architect"}
                </div>

                {/* </div> */}
              </Grid>
              <Grid item xs={8} sx={{ mt: 1.5 }}>
                <DropDownV2
                  options={billPayer}
                  initialSelectedValue={formik.values.billPayerName}
                  placeholder={"Select Bill Payer"}
                  onValueChange={(newValue) => {
                    formik.setFieldValue(
                      "billPayerName",
                      newValue.label
                    );
                    formik.setFieldValue("billPayerID", newValue.value);
                  }}
                  onSearchChange={handleSearchChange}
                  PopUp={true}
                  searchable={true}
                  addNew={true}
                  errorState={Boolean(
                    touched.billPayerName && errors.billPayerName
                  )}
                  clientType="homeOwner"
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Architect Company Name: </p>
                {/* {"Address" + ":"} */}

                {/* <div className={styles.dataSubTitle}>{"c/o if architect"}</div> */}

                {/* </div> */}
              </Grid>
              <Grid item xs={8} sx={{ mt: 1.5 }}>
                <DropDownV2
                  options={client}
                  initialSelectedValue={formik.values.architectCompanyName}
                  placeholder={"Select Architect"}
                  onValueChange={(newValue) => {
                    formik.setFieldValue(
                      "architectCompanyName",
                      newValue.label
                    );
                    formik.setFieldValue("clientID", newValue.value);
                  }}
                  onSearchChange={handleSearchChange}
                  PopUp={true}
                  searchable={true}
                  addNew={true}
                  errorState={Boolean(
                    touched.architectCompanyName && errors.architectCompanyName
                  )}
                  clientType={"professional"}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p
                  className={styles.dataTitleV2WithSub}
                  style={{ pb: 0, mb: 0 }}
                >
                  Name of Architect:{" "}
                </p>

                <div
                  className={styles.dataSubTitle}
                  style={{ marginBottom: 12 }}
                >
                  {"Informal"}
                </div>

                {/* </div> */}
              </Grid>
              <Grid item xs={8} sx={{ mt: 1.5 }}>
                <TextField
                  fullWidth
                  value={formik.values.architectName}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("architectName", e?.target?.value)
                  }
                  placeholder="Name Of Architect"
                  sx={textfeildStyle}
                  error={Boolean(touched.architectName && errors.architectName)}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Email for Issue:{" "}
                </p>

                {/* </div> */}
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.issueEmail}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("issueEmail", e?.target?.value)
                  }
                  placeholder="Issue Email"
                  sx={textfeildStyle}
                  error={Boolean(touched.issueEmail && errors.issueEmail)}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Source Of Opportunity:{" "}
                </p>

                {/* </div> */}
              </Grid>
              <Grid item xs={8} sx={{ mt: 1.5 }}>
                <DropDownV2
                  options={oppSources}
                  initialSelectedValue={formik.values.sourceOfOppName}
                  placeholder={"Select Source of Opportunity"}
                  onValueChange={(newValue) => {
                    formik.setFieldValue("sourceOfOppName", newValue.label);
                    formik.setFieldValue("sourceOfOppID", newValue.value);
                  }}
                  errorState={Boolean(
                    touched.sourceOfOppName && errors.sourceOfOppName
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  ISA Assignment:{" "}
                </p>

                {/* </div> */}
              </Grid>
              <Grid item xs={8} sx={{ mt: 1.5 }}>
                <DropDownV2
                  options={isaAssignments}
                  initialSelectedValue={formik.values.isaAssignmentName}
                  placeholder={"Select ISA Assignment"}
                  onValueChange={(newValue) => {
                    formik.setFieldValue("isaAssignmentName", newValue.label);
                    formik.setFieldValue("isaAssignmentID", newValue.value);
                  }}
                  errorState={Boolean(
                    touched.isaAssignmentName && errors.isaAssignmentName
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Job Type:{" "}
                </p>

                {/* </div> */}
              </Grid>
              <Grid item xs={8} sx={{ mt: 1.5 }}>
                <DropDownV2
                  options={jobTypes}
                  initialSelectedValue={formik.values.jobTypeName}
                  placeholder={"Select Job Type"}
                  onValueChange={(newValue) => {
                    formik.setFieldValue("jobTypeName", newValue.label);
                    formik.setFieldValue("jobTypeID", newValue.value);
                  }}
                  errorState={Boolean(
                    touched.jobTypeName && errors.jobTypeName
                  )}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={4} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Comments:{" "}
                </p>
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  value={formik.values.comment}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("comment", e?.target?.value)
                  }
                  rows={2}
                  placeholder="Comment"
                  sx={textfeildStyle}
                  //   textareaStyle={textfeildStyle}
                  inputProps={{ style: { margin: 0 } }}
                />
              </Grid>
            </Grid>
            <div className={styles.updateAndCancel}>
              <div
                className={styles.updateButton}
                type="submit"
                onClick={handleSubmit}
              >
                <div className={buttonStyles.YesButtonComponentStyling}>
                  SUBMIT
                </div>
              </div>
              <div className={styles.cancelButton} onClick={onClose}>
                <div className={buttonStyles.NoButtonComponentStyling}>
                  CANCEL
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
        {/* <ul className={styles.List}>{formFieldsConfig.map(renderField)}</ul> */}

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
      </div>
    </div>
  );
};

const OppSchema = Yup.object().shape({
  address: Yup.string().required(),
  city: Yup.string().required(),
  postcode: Yup.string().required(),
  billPayerName: Yup.string().required(),
  architectCompanyName: Yup.string().required(),
  architectName: Yup.string().required(),
  issueEmail: Yup.string().required(),
  sourceOfOppName: Yup.string().required(),
  isaAssignmentName: Yup.string().required(),
  jobTypeName: Yup.string().required(),
});

export default OppForm;
