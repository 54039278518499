import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "../../../redux/store";
import AdminContext from "../../../Context/adminContext";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import InvoiceTable from "../../../Sections/invoicing/InvoiceTable";
import Dropdown from "../../../Components/DropDownBox/Dropdown";

import styles from "../../Opportunities/Main/Opportunities.module.css";
import { Box, CircularProgress } from "@mui/material";
import ApiResponseDisplay from "../../../Components/ApiResponseDisplay/ApiResponseDisplay";

import {
  fetchInvoiceExport,
  fetchBillingPlanExport,
} from "../../../redux/slices/invoice";

function InvoiceList() {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = accounts[0];
  const API_URL = process.env.REACT_APP_API_URL;

  const admin = useContext(AdminContext);

  const [loading, setLoading] = useState(true);
  const [invoiceListData, setInvoiceList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [statusFilter, setStatusFilter] = useState("Unpaid");
  const [typeFilter, setTypeFilter] = useState("All");
  const [dueFilter, setDueFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getInvoiceList(statusFilter, typeFilter, dueFilter);
  }, []);

  const getInvoiceList = async (statusFilter, statusType, statusDue) => {
    setSearchTerm("");
    setLoading(true);

    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/list/all?status=${statusFilter}&invoiceType=${statusType}&dueOn=${statusDue}`
      );

      const data = {
        data: response.data.data,
      };
      setInvoiceList(response.data.data);
      setFilteredData(data);
    } catch (error) {
      console.error(error);
      throw new Error(`HTTP error! status: ${error.response.status}`);
    } finally {
      setLoading(false);
    }
  };

  const handlestatusFilter = (statusFilter) => {
    getInvoiceList(statusFilter, typeFilter, dueFilter);
    setStatusFilter(statusFilter);
  };

  const handleTypeFilter = (typeFilter) => {
    getInvoiceList(statusFilter, typeFilter, dueFilter);
    setTypeFilter(typeFilter);
  };

  const handleDueFilter = (dueFilter) => {
    getInvoiceList(statusFilter, typeFilter, dueFilter);
    setDueFilter(dueFilter);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);  // Set the searchTerm state immediately with the input value

    if (value.trim()) {  // Check for non-empty search after trimming white space
      const lowercasedValue = value.toLowerCase();
      const filtered = invoiceListData.filter(item =>
        Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(lowercasedValue)
        )
      );
      setFilteredData({ data: filtered });
    } else {
      setFilteredData({ data: invoiceListData });  // If search term is empty, show all data
    }
  };

  return (
    <div className={styles.opportunitiesContainer}>
      <div className={styles.OpportunitiesMainContent}>
        <div className={styles.Header}>
          <div className={styles.OpportunitiesTitle}>Invoice List</div>
          <div className={styles.ButtonContainer}>
            {admin &&
              <React.Fragment>
                <div className={`${styles.Btn} ${loading ? styles.AddDisabled : ''}`} onClick={!loading ? () => dispatch(fetchInvoiceExport()) : null}>
                  EXPORT INVOICE
                </div>
                <div className={`${styles.Btn} ${loading ? styles.AddDisabled : ''}`} onClick={!loading ? () => dispatch(fetchBillingPlanExport()) : null}>
                  EXPORT BILLING PLAN
                </div>
              </React.Fragment>
            }
          </div>
        </div>

        <div className={styles.OpportunitiesTableFilters}>
          <div className={styles.Filters}>
            <div className={styles.statusFilter}>
              <label
                className={styles.FilterLabel}
                htmlFor="statusFilter"
                style={{ fontSize: "12px" }}
              >
                Status:
              </label>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  options={[
                    "All",
                    "Planned",
                    "Draft",
                    "Raised",
                    "Unpaid",
                    "Paid",
                  ]}
                  initialSelectedValue={statusFilter}
                  onValueChange={handlestatusFilter}
                  disabled={loading}
                />
              </div>
            </div>
            <div className={styles.statusFilter}>
              <label
                className={styles.FilterLabel}
                htmlFor="statusFilter"
                style={{ fontSize: "12px", marginLeft: 40 }}
              >
                Type:
              </label>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  options={["All", "Invoice", "Credit"]}
                  initialSelectedValue={typeFilter}
                  onValueChange={handleTypeFilter}
                  disabled={loading}
                />
              </div>
            </div>
            <div className={styles.statusFilter}>
              <label
                className={styles.FilterLabel}
                htmlFor="statusFilter"
                style={{ fontSize: "12px", marginLeft: 40 }}
              >
                Due:
              </label>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  options={["All", "This Month", "Next Month"]}
                  initialSelectedValue={dueFilter}
                  onValueChange={handleDueFilter}
                  disabled={loading}
                />
              </div>
            </div>
          </div>

          <div className={styles.SearchBar}>
            <SearchBar
              value={searchTerm}
              onChange={handleSearch}
              disabled={loading}
            />
          </div>
        </div>

        {loading && (
          <div className="ProfileHolidayOverlay">
            <div
              className="ProfileHolidayResponseBox"
              style={{ borderRadius: "150px", minWidth: "160px" }}
            >
              <CircularProgress size={65} />
            </div>
          </div>
        )}
        {!loading && (
          <>
            {filteredData && (
              <InvoiceTable jsonData={filteredData} headerMap={headerMap} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default InvoiceList;
const headerMap = {
  jobNumber: "Job No",
  projectAddress: "Project Address",
  billPayer: "Bill Payer",
  team: "Team",
  status: "Status",
  type: "Type",
  plannedAmount: "Plan Amt",
  plannedDate: "Plan Date",
  invoicedAmount: "Inv.Amt",
  invoicedDate: "Inv.Date",
  dueDate: "Due Date",
  invoiceNo: "Inv. No.",
  paid: "Paid",
  comment: "Comment",
};
