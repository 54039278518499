import React, { useState, useEffect } from 'react';
import styles from '../Table/TableV2.module.css';

const ClientListTable = ({ jsonData, headerMap = {}, clickableRowAdd = false }) => {

    // Default header names mapping
    const defaultHeaderMap = {
        id: "Id",
        name: "Name",
        type: "Type",
        tags: "Tags",
        runner: "Runner",
        email: "Email",
        comment: "Comment",
    };

    // Merge the provided headerMap with defaultHeaderMap for custom header names
    const resolvedHeaderMap = { ...defaultHeaderMap, ...headerMap };

    // Extract headers in the order defined in defaultHeaderMap but exclude 'id'
    const headers = Object.keys(defaultHeaderMap)
        .filter(key => key !== 'id') // Exclude 'id' from rendering
        .map(key => resolvedHeaderMap[key] || key);

    // Initially convert JSON array to rows format
    const initialRows = jsonData?.data?.map(item =>
        Object.keys(defaultHeaderMap).map(key => {
            // Check if the current key is 'tags', if so, directly assign the array without converting to string
            return key === 'tags' ? item[key] : item[key]?.toString() || '';
        })
    );
    const [rows, setRows] = useState(initialRows);

    // Pagination state and constants
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10; // Set rows per page as desired

    // Calculate the rows to display based on the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = rows?.slice(indexOfFirstRow, indexOfLastRow);

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
        const newRows = jsonData?.data?.map(item =>
            Object.keys(defaultHeaderMap).map(key => {
                // Preserve array for 'tags', convert others to string
                return key === 'tags' ? item[key] : item[key]?.toString() || '';
            })
        );
        setRows(newRows);
    }, [jsonData]); // Only re-run the effect if jsonData changes

    const sortRows = (index) => {
        let key = index;
        let direction = 'ascending';

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        // Adjusted from [...data.rows] to directly use rows derived from jsonData
        const sortedRows = [...rows].sort((a, b) => {
            let valA = a[index];
            let valB = b[index];

            if (valA === null || valB === null) return 0;  // Skip null values

            // If value is a money string, ignore the first character and remove commas
            if (typeof valA === 'string' && valA.charAt(0) === '£') {
                valA = parseFloat(valA.substring(1).replace(/,/g, ''));
                valB = parseFloat(valB.substring(1).replace(/,/g, ''));
            }
            // If the string starts with a number, isolate the number
            else if (typeof valA === 'string' && !isNaN(valA.charAt(0))) {
                const matchA = valA.match(/\d+/);
                const matchB = valB.match(/\d+/);
                if (matchA && matchB) {
                    valA = parseFloat(matchA[0]);
                    valB = parseFloat(matchB[0]);
                }
            }
            // If the string is a date in dd/mm/yy format
            else if (typeof valA === 'string' && /^(\d{2})\/(\d{2})\/(\d{2})$/.test(valA)) {
                const [dayA, monthA, yearA] = valA.split('/').map(Number);
                const [dayB, monthB, yearB] = valB.split('/').map(Number);
                valA = new Date(yearA, monthA - 1, dayA).getTime();
                valB = new Date(yearB, monthB - 1, dayB).getTime();
            }

            if (valA < valB) return direction === 'ascending' ? -1 : 1;
            if (valA > valB) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setSortConfig({ key, direction });
        setRows(sortedRows);
    };

    const handleRowClick = (firstColumnValue) => {
        if (typeof clickableRowAdd === 'string') {
            window.location.href = `${clickableRowAdd}#${firstColumnValue}`;
        }
    };

    const rowClasses = `${styles.tableRow} ${clickableRowAdd ? styles.rowHoverHighlight : ''}`;

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead>
                    <tr className={styles.tableHeader}>
                        {headers?.map((header, index) => {
                            // Determine the sorting arrow
                            let arrow = '';
                            if (sortConfig.key === index) {
                                arrow = sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
                            }

                            if (index == 1) {
                                return (<th key={index} style={{ minWidth: "75px" }} className={styles.th}>
                                    <div className={styles.CenterContent} onClick={() => sortRows(index)}>{header}{arrow}</div>
                                </th>)
                            }

                            if (index == 1 || index == 2 || index == 3) {
                                return (<th key={index} className={styles.th}>
                                    <div className={styles.CenterContent} onClick={() => sortRows(index)}>{header}{arrow}</div>
                                </th>)
                            }

                            if (index == 3) {
                                return (<th key={index} style={{ minWidth: "100px" }} className={styles.th}>
                                    <div className={styles.CenterContent} onClick={() => sortRows(index)}>{header}{arrow}</div>
                                </th>)
                            }

                            return (
                                <th key={index} className={styles.th}>
                                    <div onClick={() => sortRows(index)}>{header}{arrow}</div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {rows?.map((row, rowIndex) => (
                        <tr key={rowIndex} className={rowClasses} onClick={() => clickableRowAdd && handleRowClick(row[0])}>
                            {row?.map((cell, cellIndex) => {
                                // Assuming the 'id' is always the first cell, skip if cellIndex is 0
                                if (cellIndex === 0) return null; // Skip the ID cell

                                if (cellIndex === 1) {
                                    return (
                                        <td key={cellIndex} style={{ maxWidth: "200px", fontFamily: "'PT Sans', sans-serif" }} className={styles.td}>
                                            <div className="ellipsis-div">{cell}</div>
                                        </td>
                                    )
                                }

                                if (cellIndex == 3) {
                                    return (
                                        <td key={cellIndex} style={{ minWidth: "100px", fontFamily: "'PT Sans', sans-serif" }} className={styles.td}>
                                            <div className={`${styles.CenterContent}`}>
                                                {cell && Array.isArray(cell) && cell.length > 0 ? (
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {cell.map((tag) => {
                                                            // Check if tag is a year
                                                            const isYear = /^19\d{2}$|^20\d{2}$/.test(tag);
                                                            let abbreviateTag;
                                                            if (isYear) {
                                                                // Abbreviate years to the last two digits
                                                                abbreviateTag = `'${tag.substring(2)}`;
                                                            } else {
                                                                // Split tag into words and abbreviate
                                                                const words = tag.split(' ');
                                                                if (words.length > 1) {
                                                                    // For multiple words, take the first letter of the first two words
                                                                    abbreviateTag = words.slice(0, 2).map(word => word[0].toUpperCase()).join('');
                                                                } else {
                                                                    // If there's only one word, use the first two letters, or the entire word if it's shorter
                                                                    abbreviateTag = tag.length > 1 ? tag.substring(0, 2).toUpperCase() : tag.toUpperCase();
                                                                }
                                                            }

                                                            return (
                                                                <div className={styles.Tag} key={tag} style={{ marginRight: '3px', display: 'flex' }}>
                                                                    {abbreviateTag}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                ) : (
                                                    <div style={{ height: '25px' }}></div>
                                                )}
                                            </div>
                                        </td>
                                    );


                                }

                                if (cellIndex == 2 || cellIndex == 4 ) {
                                    return (<td key={cellIndex} style={{ fontFamily: "'PT Sans', sans-serif" }} className={styles.td}>
                                        <div className={styles.CenterContent}>
                                            {cell}
                                        </div>
                                    </td>)
                                }

                                if (cellIndex === 7) {
                                    return (
                                        <td key={cellIndex} style={{ minWidth: "400px", fontFamily: "'PT Sans', sans-serif" }} className={styles.td}>
                                            <div className="ellipsis-div">{cell}</div>
                                        </td>
                                    )
                                }

                                return (
                                    <td key={cellIndex} className={styles.td}>
                                        <div className="ellipsis-div">{cell}</div>
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    );
};

export default ClientListTable;
