import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';


// Redux

// ----------------------------------------------------------------------

let initialState = {
    invSummaryCardData: {},
    invSummaryTableData: {},
    invLogbookData: {},
    statusActivityMap: [],
    isinvSummaryCardLoading: false,
    isinvSummaryTableLoading: false,
    isinvoiceLogBookLoading: true,
    isinvSummaryCardLoading: false,
    isinvSummaryTableLoading: false,
    error: null,
};

export const slice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        // set filter values in redux
        setInvoiceSummaryCards(state, action) {
            state.invSummaryCardData = action.payload;
            state.isinvSummaryCardLoading = false;
        },

        setInvoiceSummaryTable(state, action) {
            state.invSummaryTableData = action.payload;
            state.isinvSummaryTableLoading = false;
        },

        setInvoiceLogbookData(state, action) {
            state.invLogbookData = action.payload;
        },

        setInvoiceLogbookFilters(state, action) {
            state.statusActivityMap = action.payload;
        },

        setInvSummaryCardLoading(state, action) {
            state.isinvSummaryCardLoading = action.payload;
        },

        setInvSummaryTableLoading(state, action) {
            state.isinvSummaryTableLoading = action.payload;
        },

        setInvLogbookLoading(state, action) {
            state.isinvoiceLogBookLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isinvSummaryTableLoading = false;
            state.error = action.payload;
        },

    },
});

export const {
    hasError,
} = slice.actions;

export function getInvSummaryTable() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.setInvSummaryTableLoading(true));
        try {
            const response = await axios.get(`${API_URL}/api/invoicing/summary/table`);
            dispatch(slice.actions.setInvoiceSummaryTable(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvSummaryCards() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.setInvSummaryCardLoading(true));
        try {
            const response = await axios.get(`${API_URL}/api/invoicing/summary/cards`);
            dispatch(slice.actions.setInvoiceSummaryCards(response.data.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getLogBook() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.setInvLogbookLoading(true));
        try {
            const response = await axios.get(`${API_URL}/api/invoice/invoiceProfile/invoiceLogBook`);
            dispatch(slice.actions.setInvoiceLogbookData(response.data.data));
            dispatch(slice.actions.setInvLogbookLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setInvLogbookLoading(false));
        }
    };
}

export function postLogBook(invoiceID, status, activity, comment, user_email) {
    const API_URL = process.env.REACT_APP_API_URL;

    const CommentLog = {
        invoiceID,
        status,
        activity,
        comment,
        email: user_email,
    };

    return async () => {
        dispatch(slice.actions.setInvLogbookLoading(true));
        // NOTE TO HASSAN: Potentially include other loading variables to true for the invoice profile page
        try {
            const response = await axios.post(`${API_URL}/api/invoice/invoiceProfile/submitInvoiceLogbook`,
                CommentLog, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setInvLogbookLoading(false));
            dispatch(getLogBook());
            // NOTE TO HASSAN: Potentially include other loading variables to false for the invoice profile page
            // RELOAD ALL DATA for the page, call other APIs present in the page
        }
    };
}

export function getLogBookFilters() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        try {
            const response = await axios.get(`${API_URL}/api/invoice/invoiceProfile/LogBookFilters`);
            dispatch(slice.actions.setInvoiceLogbookFilters(response.data.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function fetchInvoiceExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the invoice data in CSV format
            const response = await axios.get(`${API_URL}/api/export/invoices`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Invoice Dashboard.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}

export function fetchMonthBillBreakdownExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the invoice data in CSV format
            const response = await axios.get(`${API_URL}/api/export/invoices/monthlybilling`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Billing Breakdown.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}

export function fetchBillingPlanExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the billing plan data in CSV format
            const response = await axios.get(`${API_URL}/api/export/billing_plans`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Billing Plans.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}


export default slice.reducer;
