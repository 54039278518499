import React, { useState, useEffect, useContext } from "react";
import {
  setStatusFilter,
  setSearchTerm,
} from "../../../redux/slices/opportunitiesdata";

import axios from "axios";
import { useMsal } from "@azure/msal-react";
import Filter from "../../../Components/Filter/Filter";
import { useSelector, useDispatch } from "../../../redux/store";
import AdminContext from "../../../Context/adminContext";
import SearchBar from "../../../Components/SearchBar/SearchBar";

import Table from "../../../Components/Table/TableV2";
import BIDTracker from "../../../Components/BIDTrackerTable/BIDTrackerTable";
import EditDiv from "../../../Components/EditDiv/EditDiv";
import Dropdown from "../../../Components/DropDownBox/Dropdown";
import Modal from "react-modal";

import {
  Card,
  Grid,
  Paper,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";

import styles from "./Opportunities.module.css";
import buttonStyle from "../../../GlobalStyles/button.module.css";
import buttonStyles from "../../../Components/YesNoButtonsCSS/YesNoButtons.module.css";
import { Box, CircularProgress } from "@mui/material";

import {
  getConfiguration,
  getRunners,
  getOpportunities,
  createOpportunity,
} from "../../../redux/slices/opportunitiesdata";

import jsonData from "./data.json";
import OppForm from "../../../Components/OppForms/OppForm";
import ApiResponseDisplay from "../../../Components/ApiResponseDisplay/ApiResponseDisplay";

function Opportunities() {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = accounts[0];

  const admin = useContext(AdminContext);

  const [filteredData, setFilteredData] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    opportunities,
    isOppListLoading,
    isNewOppLoading,
    isConfigLoading,
    error,
  } = useSelector((state) => state.opportunities);
  const [loading, setLoading] = useState(true);
  const [isResponseShown, setIsResponseShown] = useState(false);

  const searchTerm = useSelector((state) => state.opportunities.searchTerm);
  const statusFilter = useSelector((state) => state.opportunities.statusFilter);

  useEffect(() => {
    dispatch(getOpportunities(statusFilter.toLowerCase()));
    dispatch(getConfiguration());
    dispatch(getRunners());
  }, [statusFilter]);

  useEffect(() => {
    if (error && isResponseShown === false && error !== null) {
      console.log("WORKING");
      setIsResponseShown(true);
    }
  }, [error]);

  const handleFormSubmit = (formData) => {
    console.log("Form Data Submitted:", formData);

    const modifiedFormData = {
      ...formData,
      formType: "project_fee", // Add 'formType' key with value 'project_fee'
      completedBy: account.username, // Add 'completedBy' key with value 'account'
    };

    dispatch(createOpportunity(modifiedFormData));
  };

  const openOppForm = () => () => {
    setModalIsOpen(true);
  };

  const handlestatusFilter = (service) => {
    dispatch(setStatusFilter(service));
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    dispatch(setSearchTerm(value));
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // This effect updates the filtered data based on the search term
  useEffect(() => {
    // Assuming 'opportunities' is an object with a 'data' key containing the array.
    let filteredItems = opportunities.data;

    console.log(opportunities);

    // Filter by search term if it exists.
    if (searchTerm) {
      const lowercasedFilter = searchTerm.toLowerCase();
      filteredItems = filteredItems.filter((item) =>
        Object.keys(item).some((key) =>
          item[key].toString().toLowerCase().includes(lowercasedFilter)
        )
      );
    }

    console.log(filteredItems);

    // Set filtered data maintaining the original structure.
    setFilteredData({ data: filteredItems });
    setLoading(false);
  }, [searchTerm, statusFilter, opportunities]);

  return (
    <div className={styles.OpportunitiesMainContent}>
      <div className={styles.Header}>
        <div className={styles.OpportunitiesTitle}>Opportunities</div>
      </div>

      <div className={styles.OpportunitiesTableFilters}>
        <div className={styles.Filters}>
          <div className={styles.statusFilter}>
            <label
              className={styles.FilterLabel}
              htmlFor="statusFilter"
              style={{ fontSize: "12px" }}
            >
              Status:
            </label>
            <div className={styles.dropdownContainer}>
              <Dropdown
                options={["All", "Open", "Lost"]}
                initialSelectedValue={statusFilter}
                onValueChange={handlestatusFilter}
                disabled={loading}
              />
            </div>
          </div>
        </div>

        <div className={styles.SearchBar}>
          {admin && (
            <React.Fragment>
              <div
                className={`${styles.AddBtn} ${loading ? styles.AddDisabled : ""
                  }`}
                onClick={openOppForm()}
              >
                FP FORM
              </div>
            </React.Fragment>
          )}
          <SearchBar
            value={searchTerm}
            onChange={handleSearch}
            disabled={loading}
          />
        </div>
      </div>

      {isOppListLoading || isConfigLoading || isNewOppLoading && (
        <div className="ProfileHolidayOverlay">
          <div
            className="ProfileHolidayResponseBox"
            style={{ borderRadius: "150px", minWidth: "160px" }}
          >
            <CircularProgress size={65} />
          </div>
        </div>
      )}
      {!isOppListLoading && !isConfigLoading && !isNewOppLoading && (
        <>
          {modalIsOpen && (
            <OppForm onSubmit={handleFormSubmit} onClose={closeModal} />
          )}

          {filteredData && (
            <BIDTracker
              jsonData={filteredData}
              clickableRowAdd={"Opportunities/OppProfile"}
            />
          )}
        </>
      )}
      {error && error !== null && (
        <ApiResponseDisplay
          apiResponse={error}
          setIsResponseShown={setIsResponseShown}
        />
      )}
    </div>
  );
}

export default Opportunities;
