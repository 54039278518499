import React from 'react';
import { useMsal } from "@azure/msal-react";
import { useFormik } from "formik";
import { FormikProvider } from 'formik';
import textfeildStyle from "../../GlobalStyles/styles";
import { TextField } from '@mui/material';
import Dropdown from '../DropDownBox/DropdownV2';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import styles from './AppointedPopup.module.css';
import * as Yup from 'yup';

import { promoteJob } from "../../redux/slices/opportunitiesdata";
import { useDispatch } from "../../redux/store";

const AppointedPopUp = ({ isVisible, onClose, onSubmit, runners, jobNumber }) => {
    const { accounts } = useMsal();
    const account = accounts[0];
    const dispatch = useDispatch();

    // Form validation schema using Yup
    const validationSchema = Yup.object().shape({
        fee: Yup.number().min(0).required('Fee is required'),
        selectedRunner: Yup.object().shape({
            label: Yup.string().required('Runner is required'),
            value: Yup.string().required('Runner is required'),
        }),
    });

    const initialValues = {
        fee: '',
        comment: '',
        selectedRunner: {},
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            dispatch(promoteJob(
                jobNumber,
                values.comment,
                values.fee,
                values.selectedRunner.value,
                values.selectedRunner.label,
                account.username,
            ));
            onSubmit();
            onClose();
            console.log(values);
        },
    });
    const { getFieldProps, touched, errors, handleSubmit } = formik;

    if (!isVisible) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <FormikProvider value={formik} handleSubmit={handleSubmit}>
                    <p className={styles.text}>Please confirm the fee for this project</p>
                    <div className={styles.commentModal_feeSection}>
                        <TextField
                            value={formik.values.fee}
                            type="number"
                            onChange={(e) =>
                                formik.setFieldValue("fee", e?.target?.value)
                            }
                            placeholder="£"
                            sx={textfeildStyle}
                            error={Boolean(touched.fee && errors.fee)}
                        />
                    </div>
                    <div className={styles.commentModal_commentSection}>
                        <TextField
                            fullWidth
                            value={formik.values.comment}
                            type="text"
                            onChange={(e) =>
                                formik.setFieldValue("comment", e?.target?.value)
                            }
                            multiline
                            rows={1}
                            placeholder="Comment"
                            sx={textfeildStyle}
                        />
                    </div>
                    <p className={styles.text}>Please select the Runner</p>

                    <Dropdown
                        options={runners}
                        initialSelectedValue={formik.values.selectedRunner}
                        placeholder={"Select a runner..."}
                        onValueChange={(newValue) =>
                            formik.setFieldValue("selectedRunner", newValue)
                        }
                        PopUp={true}
                        errorState={Boolean(
                            touched.selectedRunner && errors.selectedRunner
                        )}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <div style={{ width: '74px', height: '25px' }}>
                            <button className={buttonStyles.YesButtonComponentStyling} onClick={handleSubmit}>SUBMIT</button>
                        </div>
                        <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}>
                            <button className={buttonStyles.NoButtonComponentStyling} onClick={onClose}>CANCEL</button>
                        </div>
                    </div>

                </FormikProvider>
            </div>
        </div>
    );
};

export default AppointedPopUp;
