import React, { useState, useEffect, useContext } from 'react';
import { setSearchValue, setSearchInputValue } from '../../../redux/slices/suppliersdata';
import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import Filter from '../../../Components/Filter/Filter';
import { useSelector, useDispatch } from '../../../redux/store';
import AdminContext from '../../../Context/adminContext';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import Table from '../../../Components/Table/TableV2';
import styles from './ClientList.module.css';
import { Box, CircularProgress } from '@mui/material';
import {
  getClientsList,
  setTypeFilter,
  setTagsFilter,
  setSearchTerm,
  setClientListLoading,
  fetchClientExport,
} from "../../../redux/slices/client";
import {
  getRunners,
  createClient
} from "../../../redux/slices/opportunitiesdata";
import ClientForm from '../../../Components/ClientForm/ClientForm';
import Dropdown from "../../../Components/DropDownBox/Dropdown";
import ClientListTable from '../../../Components/ClientListTable/ClientListTable';
import ApiResponseDisplay from '../../../Components/ApiResponseDisplay/ApiResponseDisplay';

function ClientList() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = accounts[0];
  const admin = useContext(AdminContext);
  const [addNewPopUp, setAddNewPopUp] = useState(false);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [typeOptions, setTypeOptions] = useState(['All', 'Home Owner', 'Professional']);
  const [tagOptions, setTagOptions] = useState(['All', 'Bad Debtor', 'Google Review', '2024', '2023', '2022', '2021', '2020', '2019']);
  const { typeFilter, tagsFilter, clientList, searchTerm, clientListLoading, error } = useSelector((state) => state.client);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getClientsList(typeFilter, tagsFilter));
    dispatch(getRunners());
  }, [typeFilter, tagsFilter]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    dispatch(setSearchTerm(value));
  }

  const handleAddNewClientSubmit = (formData) => {
    dispatch(createClient(formData));
    setAddNewPopUp(false);
    dispatch(setSearchInputValue(''));
    dispatch(setSearchValue(''));
  }

  const handleTypeFilter = (type) => {
    if (type == "All") {
      dispatch(setTypeFilter(''));
    } else {
      dispatch(setTypeFilter(type));
    }
  };

  const handleTagsFilter = (type) => {
    dispatch(setTagsFilter(type));
  };

  useEffect(() => {
    // Ensure clientList and clientList.data exist before proceeding
    if (clientList && Array.isArray(clientList.data)) {
      let filteredItems = clientList.data;

      // Filter by search term if it exists.
      if (searchTerm) {
        const lowercasedFilter = searchTerm.toLowerCase();
        filteredItems = filteredItems.filter(item =>
          Object.keys(item).some(key =>
            item[key] && item[key].toString().toLowerCase().includes(lowercasedFilter)
          )
        );
      }

      // Set filtered data maintaining the original structure.
      setFilteredData({ data: filteredItems });
    }
  }, [clientList, searchTerm]); // Add clientList as a dependency

  // Calculate the start and end row numbers for the current page
  const firstRow = filteredData.data ? 1 : 0;
  const lastRow = filteredData.data ? filteredData.data.length : 0;
  const totalRows = clientList?.data ? clientList?.data.length : 0;

  return (
    <div className={styles.ClientMainContent}>
      <div className={styles.ClientHeader}>
        <div className={styles.ClientTitle}>
          Client List
        </div>
        {admin &&
          <div className={`${styles.Btn} ${clientListLoading ? styles.AddDisabled : ''}`} onClick={!clientListLoading ? () => dispatch(fetchClientExport()) : null}>
            EXPORT
          </div>
        }
      </div>

      <div className={styles.ClientTableFilters}>
        <div className={styles.Filters}>
          <div className={styles.FilterContainer}>
            <label className={styles.FilterLabel} htmlFor='TypeFilter' style={{ fontSize: '12px' }}>Type:</label>
            <div className={styles.dropdownContainer}>
              <Dropdown options={typeOptions} initialSelectedValue={typeFilter} onValueChange={handleTypeFilter} disabled={clientListLoading} />
            </div>
          </div>

          <div className={styles.FilterContainer}>
            <label className={styles.FilterLabel} htmlFor='TagsFilter' style={{ fontSize: '12px' }}>Tags:</label>
            <div className={styles.dropdownContainer}>
              <Dropdown options={tagOptions} initialSelectedValue={tagsFilter} onValueChange={handleTagsFilter} disabled={clientListLoading} />
            </div>
          </div>
        </div>

        <div className={styles.SearchBar}>
          {admin && (
            <div className={`${styles.AddBtn} ${clientListLoading ? styles.AddDisabled : ''}`} onClick={!clientListLoading ? () => setAddNewPopUp(true) : null}>
              ADD
            </div>
          )}
          <SearchBar value={searchTerm} onChange={handleSearch} disabled={clientListLoading} />
        </div>
      </div>

      {clientListLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '100px', justifyContent: 'center' }}>
          <CircularProgress size={65} />
        </Box>
      ) : (
        <React.Fragment>
          <div className={styles.TableView}>
            <ClientListTable jsonData={filteredData} clickableRowAdd={'clients/ClientProfile'} />
          </div>
          <div className={styles.paginationContainer}>
            <div className={styles.paginationInfo}>
              <span>{`Showing ${firstRow}-${lastRow} of ${totalRows} clients`}</span>
            </div>
          </div>

          {error && error !== null && (
            <ApiResponseDisplay apiResponse={error} setIsResponseShown={setIsResponseShown} />
          )}

          {addNewPopUp && (
            <ClientForm
              Name={''}
              onSubmit={handleAddNewClientSubmit}
              onClose={() => setAddNewPopUp(false)}
            />
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default ClientList;