import React, { useState, useEffect } from 'react';
import styles from './EditDiv.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import DropDown from '../DropDownBox/Dropdown';
import BlueLogo from '../../assets/BlueLogo.png';
import DatePicker from '../DatePicker/DatePicker';
import CommentBox from '../CommentBox/CommentBox';
import TickBox from '../TickBox/TickBox';
import TwoButtonsInput from '../TwoButtonsInput/TwoButtonsInput';

function EditDiv({data, handleUpdateClick, setDisplayEditDiv, actionType, required = false}) {
  const [selectedValues, setSelectedValues] = useState({});
  const [checkBoxStates, setCheckBoxStates] = useState({});

  const [invalidInputs, setInvalidInputs] = useState([]);

  // Initialize checkbox states from data.DataEdit
  useEffect(() => {
    const initialCheckBoxStates = {};
    data.DataEdit.forEach(({ dataName, checked }) => {
      if (checked !== undefined) {
        initialCheckBoxStates[dataName] = checked;
      }
    });
    setCheckBoxStates(initialCheckBoxStates);
  }, [data]);

  const updateSelectedValue = (dataName) => (newValue) => {
    console.log(`Updating ${dataName} with ${newValue}`); // add this line
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [dataName]: newValue
    }));
  };

  const validateAndSubmit = () => {
    let invalids = [];
    
    if (required) { // Only check if required is true
      data.DataEdit.forEach(({editType, dataName}) => {
        // Adding condition for 'New Client'
        if (data.DataEditTitle === 'New Client') {
          if ((editType === 'TextBox' || editType === 'LargeTextBox') && !['Address 2', 'Town', 'Comment'].includes(dataName) && !selectedValues[dataName]) {
            invalids.push(dataName);
          }
        } else {
          // Original condition for other cases
          if ((editType === 'TextBox' || editType === 'LargeTextBox') && !selectedValues[dataName]) {
            invalids.push(dataName);
          }
        }
      });
    }
    
  
    if (invalids.length > 0) {
      setInvalidInputs(invalids);
  
      const timer = setTimeout(() => {
        setInvalidInputs([]);
      }, 500); // clear after 0.5 seconds
  
      return () => clearTimeout(timer); // clear timer if the component unmounts
    } else {
      handleUpdateClick(data.DataEditTitle, selectedValues);
      handleClose();
    }
  };

  const handleCheckBoxClick = (dataName, checked) => {
    setCheckBoxStates({
      ...checkBoxStates,
      [dataName]: !checked
    });
  };

  const handleClose = () => {
    setDisplayEditDiv(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();  // Stops the click event from bubbling up to the parent div
  };

  return (
    <div className={styles.backGround} style={{ position: 'fixed' }} onClick={handleClose}>
      <div 
        className={styles.form} 
        style={{ 
          width: data.DataEditTitle === "Invoice Request" ? "auto" : 
                data.DataEditTitle === "Edit Invoice Request" ? "401px" : "418px" 
        }} 
        onClick={handleFormClick}
      >
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>{data.DataEditTitle}</div>
        
        <ul className={styles.List}>
          {data.DataEdit.map(({editType, dataName, data, options, checked, colour}) => {
            switch(editType) {
              case 'NonEditable':
                return (
                  <li className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.NonEditable}>
                      {data}
                    </div>
                  </li>
                );
              case 'Date':
                return (
                  <li className={styles.dataRow}>
                  <div className={styles.dataTitle}>
                    {dataName + ':'}
                  </div>
                  <div className={styles.commentHolder}>
                    {
                      (() => {
                        if (dataName === "Invoice Date") {
                          console.log('Invoice Request condition met');
                          return <DatePicker dataName={dataName} initialDate={data} onDatesChange={updateSelectedValue(dataName)} keepEmpty={false} visableIcon={true} />;
                        } else if (data && data.length === 0) {
                          // Added check for data being not null
                          return <DatePicker dataName={dataName} initialDate={data} onDatesChange={updateSelectedValue(dataName)} keepEmpty={true} />;
                        } else {
                          return <DatePicker dataName={dataName} initialDate={data} onDatesChange={updateSelectedValue(dataName)} />;
                        }
                      })()
                    }
                  </div>


                </li>
                );
              case 'Btns&Other':
                return (
                  <li className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.commentHolder}>
                      <TwoButtonsInput FirstBtn={data.BtnOneValue} SecondBtn={data.BtnTwoValue} OtherPlaceHolder={data.OtherPlaceHolder} hexCode={data.BtnColour} onValueChange={updateSelectedValue(dataName)} />
                    </div>
                  </li>
                );
              case 'DropDown':
                return (
                  <li className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.commentHolder}>
                      <DropDown options={options} initialSelectedValue={data} onValueChange={updateSelectedValue(dataName)} PopUp={true} />
                    </div>
                  </li>
                );
                case 'MultiDropDown':
                return (
                  <li className={styles.dataRow}>
                    <div className={styles.dataTitle}>
                      {dataName + ':'}
                    </div>
                    <div className={styles.commentHolder}>
                      <DropDown options={options} initialSelectedValue={data} onValueChange={updateSelectedValue(dataName)} PopUp={true} mode='multi' />
                    </div>
                  </li>
                );
                case 'CheckBox':
                  return (
                    <li className={styles.dataRow}>
                      <div className={styles.dataTitle}>
                        {dataName + ':'}
                      </div>
                      <div className={styles.commentHolder}>
                        <TickBox 
                          type={checkBoxStates[dataName] || false} 
                          tickClick={() => {
                            updateSelectedValue(dataName)(!checkBoxStates[dataName]); 
                            handleCheckBoxClick(dataName, checkBoxStates[dataName]);
                          }} 
                          coloureis={colour}
                        />
                      </div>
                    </li>
                  );
                case 'TextBox':
                case 'LargeTextBox':
                  return (
                    <li className={styles.dataRow} key={`${dataName}-${editType}`}>
                      <div className={styles.dataTitle}>
                        {dataName + ':'}
                      </div>
                      <div className={styles[`${editType}Holder`]}>
                        <CommentBox initialData={data} onValueChange={updateSelectedValue(dataName)} invalid={invalidInputs.includes(dataName)} />
                      </div>
                    </li>
                  );
              default:
                return null;
            }})
          }
        </ul>
        
        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={validateAndSubmit}>
            <div className={buttonStyles.YesButtonComponentStyling}>{actionType}</div>
          </div>
          <div className={styles.cancelButton} onClick={handleClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo"/>
      </div>
    </div>
  );
}

export default EditDiv;