import React, { useState, useEffect } from "react";
import styles from "../../Components/Table/TableV2.module.css";
import Tick from "../../assets/Tick.svg";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import MoreOptionsV2 from "../../Components/MoreOptions/MoreOptionsV2.js";
import { TextField, Select, MenuItem } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { VariantType, useSnackbar } from "notistack";
import DropDownV2 from "../../Components/DropDownBox/DropdownV2";
import textfeildStyle from "../../GlobalStyles/styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
// import "./invoice.css";
import { useSelector, useDispatch } from "../../redux/store";

const BillPayerTable = ({
  jobNumber,
  jsonData,
  headerMap = {},
  clickableRowAdd = false,
  handleRowEdit,
  // setRowData,
  rowD,
  disabled = false,
}) => {
  const { accounts } = useMsal();
  const account = accounts[0];
  const API_URL = process.env.REACT_APP_API_URL;
  const { enqueueSnackbar } = useSnackbar();
  // const { isSubmitting, raiseInvoiceAssignedBilling } = useSelector(
  //   (state) => state.raiseInvoice
  // );
  const [rowData, setRowData] = useState([]);
  const dispatch = useDispatch();
  // useEffect(() => {

  //   if (raiseInvoiceAssignedBilling.length > 0) {
  //     const data = raiseInvoiceAssignedBilling
  //     setRowData(data);
  //   }
  // }, [raiseInvoiceAssignedBilling]);

  useEffect(() => {
    setRowData(rowD);
  }, []);
  const resolvedHeaderMap = headerMap;
  const headers = Object.keys(resolvedHeaderMap).map(
    (key) => resolvedHeaderMap[key] || key
  );

  const handleRowUpdate = (key, comingRow, value) => {
    const updatedRows = [...rowData];
    setRowData(
      updatedRows.map((item) => {
        if (item.billPayerID === comingRow.billPayerID) {
          return { ...item, [key]: value };
        } else {
          return { ...item, [key]: false };
        }
      })
    );

    postDefaultBillPayer(comingRow.billPayerID);
  };

  const postDefaultBillPayer = async (billPayerID) => {
    const body = {
      billPayerID: billPayerID,
      jobNumber: jobNumber,
      updatedBy: account.username,
    };
    try {
      const result = await axios.post(
        `${API_URL}/api/invoice/project/default/bill/payer`,
        body
      );
      if (result.data.success) {
        enqueueSnackbar("Request completed successfully!", {
          variant: "success",
        });
      }else{
        enqueueSnackbar("Error in updating  Bill payer!", {
          variant: "success",
        });
      }
     
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const rowClasses = `${styles.tableRow} ${
    clickableRowAdd ? styles.rowHoverHighlight : ""
  }`;

  return (
    <div
      className={styles.tableContainer}
      style={{
        maxHeight: "300px",
        height: "300px",
        overflowY: "auto",
        overflowX: "hidden",
        background: "inherit",
      }}
    >
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            {headers?.map((header, index) => {
              // Determine the sorting arrow
              let arrow = "";

              // if (index === 0) {
              //   return <th className={styles.th} colspan="1"></th>;
              // }
              if (
                index == 2 ||
                index == 4 ||
                index == 3 ||
                index == 4 ||
                index == 5 ||
                index == 6 ||
                index == 7
              ) {
                return (
                  <th key={index} className={styles.th}>
                    <div
                      className={styles.CenterContent}
                      //   onClick={() => sortRows(index)}
                    >
                      {header}
                      {arrow}
                    </div>
                  </th>
                );
              }

              return (
                <th key={index} className={styles.th}>
                  <div>
                    {header}
                    {arrow}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {
          rowData.length === 0 ? 
            <tr
              className={rowClasses}
              style={{ background: "white" }}
            >
              <td colSpan={4} className={styles.tdNoInfo} style={{paddingBottom: '15px'}}>
                No information on record.
                </td>
            </tr>
          : rowData?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowClasses}
              style={{ background: "white" }}
            >
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "3%" }}
                className={styles.td}
              >
                <div>
                  <Checkbox
                    disabled={disabled}
                    checked={row.default}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleOutlineIcon />}
                    style={{ pl: 20 }}
                    onChange={(e) =>
                      handleRowUpdate("default", row, !row.isSelected)
                    }
                  />
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "4%" }}
                className={styles.td}
              >
                <div>{row.name}</div>
              </td>

              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "7%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>{row.email}</div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "50%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>{row.address}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BillPayerTable;
