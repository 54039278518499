import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useSearchParams } from "react-router-dom";
import {
  Grid,
  Stack,
  TextField,
  Skeleton,
  CircularProgress,
  Box,
} from "@mui/material";
import "../Projects/ProjectProfile/ProjectProfile.css";
import "./RaiseInvoice.css";
import textfeildStyle from "../../GlobalStyles/styles";
import styles from "../../Pages/Client/ClientProfile/ClientProfile.module.css";
import BackBtn from "../../assets/BackBtn.svg";
import FolderIcon from "../../assets/folder.svg";
import DropdownLogbook from "../../Components/DropDownSelect/Dropdown";
import Dropdown from "../../Components/DropDownBox/DropdownV2";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "../../Components/DatePicker/DatePicker";
import PhaseTable from "../../Sections/raise-invoice/PhaseTable";
import { VariantType, useSnackbar } from "notistack";
import { useSelector, useDispatch } from "../../redux/store";

import "../Invoicing/Invoicing.module.css";
import {
  getRaiseInvoiceDetails,
  getRaiseInvoicePhases,
  getRaiseInvoiceHourlyRates,
  getRaiseInvoiceAssignedBilling,
  getLogBookFilters,
  getLogBook,
  postDraftInvoice,
  putDraftInvoice,
  getDraftedInvoicePhases,
  getDraftedInvoiceHourlyRates,
  getDraftedInvoiceAssignedBilling,
  getRaisedInvoicePhases,
  getRaisedInvoiceHourlyRates,
  getRaisedInvoiceAssignedBilling,
  generateInvoice,
  refreshSuccess,
  clearInvoiceData,
  updateInvoiceAddress,
  getDraftedCreditPhases,
} from "../../redux/slices/raiseInvoice";
import HourlyRateTable from "../../Sections/raise-invoice/HourlyRateTable";
import AssignedBillingTable from "../../Sections/raise-invoice/AssignedBillingTable";
import InvoicePaidPopUp from "../../Sections/raise-invoice/InvoicePaidPopUp";
import moment from "moment";

function RaiseInvoice() {
  const { accounts } = useMsal();
  const account = accounts[0];
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jobNumber = searchParams.get("jobNumber");
  const invoiceNumber = searchParams.get("invoiceNumber");
  const jobStatus = searchParams.get("status");
  const {
    raiseInvoicePhaseLoading,
    raiseInvoiceHourlyRateLoading,
    raiseInvoiceAssignedBillingLoading,
    raiseInvoiceDetails,
    raiseInvoiceDetailsLoading,
    statusActivityMap,
    isinvoiceLogBookLoading,
    invLogbookData,
    invPostLoading,
    showError,
    showSuccess,
  } = useSelector((state) => state.raiseInvoice);

  const [status_type_options, setStatusTypes] = useState([]);
  const [status, setStatus] = useState(null);
  const [activity, setActivity] = useState("");
  const [activityOptions, setActivityOptions] = useState(
    statusActivityMap[status] || []
  );
  const [paidPopUp, showPaidPopUp] = useState(false);
  const [allDisabled, setAllDisabled] = useState(false);

  const [selectedInvoiceType, setSelectedInvoiceType] = useState({
    // label: "Invoice",
    // value: "invoice",
  });
  // Phase table states
  const [phaseTableRows, setPhaseTableRows] = useState([]);

  const [hourlyRateTableRows, setHourlyRateTableRows] = useState([]);

  const [billingTableRows, setBillingTableRows] = useState([]);

  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("..");
    }
  };

  useEffect(() => {
    if (showError) {
      enqueueSnackbar("Something went wrong with request!", {
        variant: "error",
      });
    }
  }, [showError]);

  useEffect(() => {
    if (showSuccess) {
      enqueueSnackbar("Request completed successfully!", {
        variant: "success",
      });
      dispatch(refreshSuccess());
    }
  }, [showSuccess]);

  useEffect(() => {
    if (jobNumber) {
      if (invoiceNumber) {
        dispatch(getRaiseInvoiceDetails(jobNumber, invoiceNumber));
      } else {
        dispatch(getRaiseInvoiceDetails(jobNumber));
      }

      getPhasesHourlyAndBillings();

      dispatch(getLogBookFilters());
      dispatch(getLogBook(invoiceNumber));
    }
  }, []);

  const getPhasesHourlyAndBillings = () => {
    if (jobStatus?.toLowerCase() == "start") {
      dispatch(getRaiseInvoiceHourlyRates(jobNumber));
      dispatch(getRaiseInvoiceAssignedBilling(jobNumber));
    }
    if (jobStatus?.toLowerCase() == "draft" && invoiceNumber) {
      dispatch(getDraftedInvoiceHourlyRates(jobNumber, invoiceNumber));
      dispatch(getDraftedInvoiceAssignedBilling(jobNumber, invoiceNumber));
    } else if (
      (jobStatus?.toLowerCase() == "raised" ||
        jobStatus?.toLowerCase() == "paid") &&
      invoiceNumber
    ) {
      dispatch(getRaisedInvoiceHourlyRates(jobNumber, invoiceNumber));
      dispatch(getRaisedInvoiceAssignedBilling(jobNumber, invoiceNumber));
    }
  };

  // const getConditionalPhases = (type) => {
  //   if(type === 'credit'){

  //   }
  //   if (jobStatus?.toLowerCase() == "start") {
  //     dispatch(getRaiseInvoicePhases(jobNumber));
  //   }
  //   if (jobStatus?.toLowerCase() == "draft" && invoiceNumber) {
  //     dispatch(getDraftedInvoicePhases(jobNumber, invoiceNumber));
  //   } else if (jobStatus?.toLowerCase() == "raised" && invoiceNumber) {
  //     dispatch(getRaisedInvoicePhases(jobNumber, invoiceNumber));
  //   }
  // };

  useEffect(() => () => dispatch(clearInvoiceData()), []);

  useEffect(() => {
    if (Object.keys(statusActivityMap).length > 0) {
      const activityKeys = Object.keys(statusActivityMap);
      activityKeys.sort(
        (a, b) => statusActivityMap[b].length - statusActivityMap[a].length
      );
      if (
        raiseInvoiceDetails.status == "" ||
        raiseInvoiceDetails.status == "draft"
      ) {
        setStatusTypes(activityKeys);
      }
      if (raiseInvoiceDetails.status == "raised") {
        setStatusTypes(
          activityKeys.filter((key) => key == "Raised" || key == "Paid")
        );
      }
      if (raiseInvoiceDetails.status == "paid") {
        setStatusTypes(activityKeys.filter((key) => key == "Raised"));
      }
      console.log(raiseInvoiceDetails, "statusActivityMap");
    }
  }, [statusActivityMap, raiseInvoiceDetails]);

  const handleStatusChange = (value) => {
    setStatus(value);
    // Check if statusActivityMap[value] is not undefined before setting activityOptions
    if (statusActivityMap[value]) {
      setActivityOptions(statusActivityMap[value]);
    } else {
      setActivityOptions([]); // If statusActivityMap[value] is undefined, set activityOptions to an empty array
    }
  }; // pass statusActivityMap as a dependency

  const handleActivityChange = (value) => {
    setActivity(value);
  };

  const initialValues = {
    invoiceType: "Invoice",
    paymentTerms: "14 Days",
    invoiceDate: moment().format("DD/MM/YYYY"),
    address: "",
    comment: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: InvoiceSchema,
    onSubmit: async (values) => {
      // if (!validateValues(values)) {
      //   return;
      // }
    },
  });
  const { touched, errors, handleSubmit } = formik;

  useEffect(() => {
    if (raiseInvoiceDetails) {
      console.log(raiseInvoiceDetails, "raiseInvoiceDetails");
      if (
        raiseInvoiceDetails.status == "raised" ||
        raiseInvoiceDetails.status == "paid"
      ) {
        setAllDisabled(true);
      }
      if (raiseInvoiceDetails.invoiceType == "credit") {
        setSelectedInvoiceType({
          label: "Credit",
          value: "credit",
        });
        formik.setFieldValue("invoiceType", "Credit");
        formik.setFieldValue("invoiceTypeID", "credit");
        updateInvoiceType("credit");
      } else if (
        raiseInvoiceDetails.invoiceType == "invoice" ||
        raiseInvoiceDetails.invoiceType == ""
      ) {
        setSelectedInvoiceType({
          label: "Invoice",
          value: "invoice",
        });
        formik.setFieldValue("invoiceType", "Invoice");
        formik.setFieldValue("invoiceTypeID", "invoice");

        updateInvoiceType("invoice");
      }
      if (raiseInvoiceDetails.address) {
        formik.setFieldValue("address", raiseInvoiceDetails.address);
      } else {
        formik.setFieldValue("address", raiseInvoiceDetails.billPayer?.address);
        formik.setFieldValue("address", raiseInvoiceDetails.billPayer?.address);
      }
      if (
        raiseInvoiceDetails.invoiceDate &&
        raiseInvoiceDetails.invoiceDate != ""
      ) {
        formik.setFieldValue(
          "invoiceDate",
          moment(raiseInvoiceDetails.invoiceDate, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          )
        );
      }
      if (raiseInvoiceDetails.paymentTerms) {
        formik.setFieldValue("paymentTerms", raiseInvoiceDetails.paymentTerms);
      }
      if (raiseInvoiceDetails.billPayer?.isBadDebtor) {
        formik.setFieldValue("paymentTerms", "Payment on Appointment");
      }
    }
  }, [raiseInvoiceDetails]);

  const validateValues = (values) => {
    const billingData = billingTableRows.filter((row) => row.isSelected);
    const phaseTableData = phaseTableRows.filter((row) => row.isSelected);
    if (billingData.length != 1) {
      enqueueSnackbar("Please select Assigned billing!", { variant: "error" });
      return false;
    }
    if (phaseTableData.length == 0) {
      enqueueSnackbar("Please select Phases!", { variant: "error" });
      return false;
    }

    const phaseAmounts = phaseTableData.reduce((acc, cur) => {
      return parseFloat(acc) + parseFloat(cur.invoiceAmount);
    }, 0);
    if (phaseAmounts !== billingData[0].plannedAmount) {
      enqueueSnackbar("Billing Amount doesn't match with Phases Amount", {
        variant: "error",
      });
      return false;
    }

    // Gross total check
    // if (phaseTableRows.filter((row) => row.isSelected).length != 1) {
    //   enqueueSnackbar("Please select Phases!", { variant: "error" });
    //   return false;
    // }

    return true;
  };

  const onPostCall = () => {
    switch (status?.toLowerCase()) {
      case "draft": {
        updateDraftInvoice(status, activity);
        break;
      }
      case "raised": {
        if (activity == "Address Update") {
          // if(status == "raised") {
          const values = formik.values;
          const logBookbody = {
            invoiceID: invoiceNumber ? invoiceNumber : "",
            status: status?.toLowerCase(),
            activity: activity,
            comment: values.comment,
            email: account.username,
          };
          const body = {
            jobNumber: jobNumber,
            invoiceNumber: invoiceNumber ? invoiceNumber : "",
            address: values.address,
            // updatedBy: account.username,
          };
          dispatch(updateInvoiceAddress(body, logBookbody));
          // }else{
          //   enqueueSnackbar("Address cant be updated!", {
          //     variant: "error",
          //   });
          // }
        } else {
          updateDraftInvoice(status, activity);
        }
        break;
      }
      case "paid": {
        showPaidPopUp(true);
        break;
      }
    }
    // formik.setFieldValue("comment", "");
  };

  const updateDraftInvoice = (status, activity) => {
    if (activity == "Raised Update" && !validateValues(formik.values)) {
      return;
    }
    const enableHourly =
      phaseTableRows.filter((row) => row.phaseName.includes("Hourly")).length >
      0
        ? true
        : false;

    const values = formik.values;
    const body = {
      jobNumber: parseInt(jobNumber),
      invoiceDate: moment(values.invoiceDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      invoiceType: values.invoiceType,
      paymentTerms: values.paymentTerms,
      address: values.address,
      defaultBillPayerID: raiseInvoiceDetails.billPayer.defaultID,
      phases: phaseTableRows.map((row) => {
        return {
          phaseID: row.phaseID,
          invoicedPhaseID: row.invoicedPhaseID,
          invoiceAmount: row.invoiceAmount,
          nominalCode: row.nominalCode,
          vat: row.vat,
          comment: row.comment,
          isSelected: row.isSelected,
        };
      }),
      hourlyRates: hourlyRateTableRows.map((row) => {
        return {
          hourlyRateID: row.hourlyRateID,
          invoiceNumber: row.invoiceNumber,
          fullName: row.fullName,
          isSelected: row.isSelected && enableHourly,
          invoicedQuantity: row.invoicedQuantity,
          entryDate: moment(row.entryDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
          quantity: row.quantity,
          chargeRate: row.chargeRate,
          comment: row.comment,
          draftHistoryID: row.draftHistoryID,
        };
      }),
      assignedBilling: billingTableRows.map((row) => {
        return {
          plannedInvoiceID: row.plannedInvoiceID,
          invoiceNumber: row.invoiceNumber,
          isSelected: row.isSelected,
          plannedAmount: row.plannedAmount,
        };
      }),
      updatedBy: account.username,
    };
    const logBookbody = {
      invoiceID: invoiceNumber ? invoiceNumber : "",
      status: status?.toLowerCase(),
      activity: activity,
      comment: values.comment,
      email: account.username,
    };
    if (
      jobStatus?.toLowerCase() == "draft" ||
      jobStatus?.toLowerCase() == "raise"
    ) {
      body.invoiceNumber = parseInt(invoiceNumber);
      body.status = status?.toLowerCase();
      dispatch(
        putDraftInvoice(body, logBookbody, raiseInvoiceDetails.invoiceType)
      );
    } else {
      dispatch(postDraftInvoice(body, logBookbody));
    }
  };

  const updateInvoiceType = (type) => {
    if (type == "credit" && jobStatus?.toLowerCase() == "start") {
      dispatch(getDraftedCreditPhases(jobNumber));
    } else {
      if (jobStatus?.toLowerCase() == "start") {
        dispatch(getRaiseInvoicePhases(jobNumber));
      }
      if (jobStatus?.toLowerCase() == "draft" && invoiceNumber) {
        dispatch(getDraftedInvoicePhases(jobNumber, invoiceNumber));
      } else if (
        (jobStatus?.toLowerCase() == "raised" ||
          jobStatus?.toLowerCase() == "raised" ||
          jobStatus?.toLowerCase() == "paid") &&
        invoiceNumber
      ) {
        dispatch(getRaisedInvoicePhases(jobNumber, invoiceNumber));
      }
    }
  };

  const findInvoiceType = (raiseInvoiceDetails) => {
    if (raiseInvoiceDetails.invoiceType == "credit") {
      return {
        label: "Credit",
        value: "credit",
      };
    } else {
      return {
        label: "Invoice",
        value: "invoice",
      };
    }
  };
  const findPaymentTerms = (raiseInvoiceDetails) => {
    return raiseInvoiceDetails.paymentTerms
      ? raiseInvoiceDetails.paymentTerms == "Payment on Appointment"
        ? {
            label: "Payment on Appointment",
            value: 2,
          }
        : {
            label: "14 Days",
            value: 1,
          }
      : raiseInvoiceDetails.billPayer?.isBadDebtor
      ? {
          label: "Payment on Appointment",
          value: 2,
        }
      : {
          label: "14 Days",
          value: 1,
        };
  };
  return (
    <>
      {invPostLoading && (
        <div className="ProfileHolidayOverlay">
          <div
            className="ProfileHolidayResponseBox"
            style={{ borderRadius: "150px", minWidth: "160px" }}
          >
            <CircularProgress size={65} />
          </div>
        </div>
      )}

      {jobNumber && (
        <FormikProvider value={formik}>
          <div className="raise-content">
            {raiseInvoiceDetailsLoading && (
              <Box
                sx={{
                  m: "auto",
                  width: "5%",
                  p: 5,
                }}
              >
                <CircularProgress></CircularProgress>
              </Box>
            )}
            {!raiseInvoiceDetailsLoading && raiseInvoiceDetails.jobNumber && (
              <div className="ProjectBaseInfo basicMargin">
                <div className="BackBtn" onClick={handleBack}>
                  <img src={BackBtn} alt="Back" />
                </div>
                <div className="ProjectAddress">
                  {invoiceNumber
                    ? invoiceNumber
                    : raiseInvoiceDetails.jobNumber}
                </div>
                <div className="ProjectArchitect">
                  {invoiceNumber && raiseInvoiceDetails.jobNumber} {" - "}
                  {raiseInvoiceDetails?.jobAddress}
                </div>
              </div>
            )}
            {!raiseInvoiceDetailsLoading && raiseInvoiceDetails.jobNumber && (
              <div className="ProjectTags basicMargin">
                {/* Project Tags */}
                <ul>
                  {raiseInvoiceDetails.billPayer?.isBadDebtor && (
                    <li className="Tag" key={"tag"}>
                      Bad Debtor
                    </li>
                  )}
                </ul>

                <div className="LeftAlignment">
                  <div
                    className="button-secondary mr20"
                    onClick={generateInvoice(
                      formik.values.invoiceType,
                      jobNumber,
                      invoiceNumber
                    )}
                  >
                    GENERATE
                  </div>

                  <div className="button-primary">
                    {raiseInvoiceDetails?.status
                      ? raiseInvoiceDetails?.status == "raised"
                        ? "RAISED"
                        : raiseInvoiceDetails?.status.toUpperCase()
                      : "DRAFT"}
                  </div>
                </div>
              </div>
            )}

            <div
              className="ProjectProfileScrollableArea"
              style={{ height: "calc(100% - 260px)" }}
            >
              {!raiseInvoiceDetailsLoading && raiseInvoiceDetails.jobNumber && (
                <>
                  {" "}
                  <div
                    className="ProjectInformation"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      className="ProjectDetails"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: "1",
                      }}
                    >
                      {" "}
                      <Grid container spacing={2} sx={{ pt: 4 }}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={3}>
                          <div className="card">
                            <Grid container sx={{ height: 35 }}>
                              <Grid item xs={4}>
                                <p className="dataTitleV2">Invoice Date:</p>
                              </Grid>
                              <Grid item xs={8} sx={{ pl: 2, pr: 2, mt: 1.2 }}>
                                <DatePicker
                                  onDatesChange={(newValue) => {
                                    console.log(newValue, "newValue");
                                    formik.setFieldValue(
                                      "invoiceDate",
                                      moment(
                                        newValue.startDate,
                                        "YYYY-MM-DDTHH:mm:ss.000Z"
                                      ).format("DD/MM/YYYY")
                                    );
                                  }}
                                  initialDate={formik.values.invoiceDate}
                                  visableIcon={true}
                                  disabled={allDisabled}
                                />
                              </Grid>
                            </Grid>
                            <Grid container sx={{ height: 35 }}>
                              <Grid item xs={4}>
                                <p className="dataTitleV2">Invoice Type:</p>
                              </Grid>
                              <Grid item xs={8} sx={{ pl: 2, pr: 2, mt: 1.2 }}>
                                {" "}
                                <Dropdown
                                  disabled={allDisabled}
                                  options={invoiceTypes}
                                  initialSelectedValue={findInvoiceType(
                                    raiseInvoiceDetails
                                  )}
                                  // initialSelectedValue={selectedInvoiceType}
                                  placeholder={"Invoice Type"}
                                  onValueChange={(newValue) => {
                                    formik.setFieldValue(
                                      "invoiceType",
                                      newValue.label
                                    );
                                    formik.setFieldValue(
                                      "invoiceTypeID",
                                      newValue.value
                                    );
                                    updateInvoiceType(newValue.value);
                                  }}
                                  errorState={Boolean(
                                    touched.invoiceType && errors.invoiceType
                                  )}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={4}>
                                <p className="dataTitleV2">Payment Terms</p>
                              </Grid>
                              <Grid item xs={8} sx={{ pl: 2, pr: 2, mt: 1.2 }}>
                                {" "}
                                <Dropdown
                                  disabled={allDisabled}
                                  options={paymentTerms}
                                  initialSelectedValue={findPaymentTerms(
                                    raiseInvoiceDetails
                                  )}
                                  placeholder={"Payment Terms"}
                                  onValueChange={(newValue) => {
                                    console.log(newValue, "newValue");
                                    formik.setFieldValue(
                                      "paymentTerms",
                                      newValue.label
                                    );
                                    formik.setFieldValue(
                                      "paymentTermsID",
                                      newValue.value
                                    );
                                  }}
                                  errorState={Boolean(
                                    touched.paymentTerms && errors.paymentTerms
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="card">
                            <Grid container sx={{ height: 120 }}>
                              <Grid item xs={2}>
                                <p className="dataTitleV2">Address</p>
                              </Grid>
                              <Grid item xs={10} sx={{ pl: 2, pr: 2, mt: 1.2 }}>
                                {" "}
                                <TextField
                                  fullWidth
                                  multiline
                                  value={formik.values.address}
                                  type="text"
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "address",
                                      e?.target?.value
                                    )
                                  }
                                  rows={2}
                                  placeholder="Address"
                                  sx={textfeildStyle}
                                  //   textareaStyle={textfeildStyle}
                                  inputProps={{ style: { margin: 0 } }}
                                  error={Boolean(
                                    touched.address && errors.address
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div className="card">
                            <Grid container sx={{ height: 120, pt: 1 }}>
                              <Grid item xs={5} sx={{ height: 12 }}>
                                <p className="dataTitleV2 largeText">Fee:</p>
                              </Grid>
                              <Grid item xs={6} sx={{ pl: 2, pr: 2, mt: 0.2 }}>
                                {" "}
                                <p className="dataTitle">
                                  {" "}
                                  £{raiseInvoiceDetails?.fee}
                                </p>
                              </Grid>
                              <Grid item xs={5} sx={{ height: 12 }}>
                                <p className="dataTitleV2 largeText">
                                  Invoiced:
                                </p>
                              </Grid>
                              <Grid item xs={6} sx={{ pl: 2, pr: 2, mt: 0.2 }}>
                                {" "}
                                <p className="dataTitle">
                                  {" "}
                                  £{raiseInvoiceDetails?.invoiced}
                                </p>
                              </Grid>
                              <Grid item xs={5} sx={{ height: 12 }}>
                                <p className="dataTitleV2 largeText">Paid:</p>
                              </Grid>
                              <Grid item xs={6} sx={{ pl: 2, pr: 2, mt: 0.2 }}>
                                {" "}
                                <p className="dataTitle">
                                  £{raiseInvoiceDetails?.paid}
                                </p>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </>
              )}

              <Grid
                container
                // spacing={4}
                padding={4}
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                {raiseInvoicePhaseLoading && (
                  <Box
                    sx={{
                      m: "auto",
                      width: "5%",
                      p: 5,
                    }}
                  >
                    <CircularProgress></CircularProgress>
                  </Box>
                )}
                {!raiseInvoicePhaseLoading && (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    <div
                      className="SubTitle"
                      style={{ marginBottom: "5px", marginTop: 0 }}
                    >
                      {" "}
                      Phases{" "}
                    </div>
                    <PhaseTable
                      jsonData={[]}
                      headerMap={headerMapPhase}
                      handleRowEdit={() => {}}
                      setRowData={setPhaseTableRows}
                      rowData={phaseTableRows}
                      disabled={allDisabled}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                spacing={4}
                padding={4}
                sx={{ marginTop: 0, paddingTop: 0 }}
              >
                <Grid item md={8} xs={8} sx={{ marginTop: 0, paddingTop: 0 }}>
                  {raiseInvoiceHourlyRateLoading && (
                    <Box
                      sx={{
                        m: "auto",
                        width: "5%",
                        p: 5,
                      }}
                    >
                      <CircularProgress></CircularProgress>
                    </Box>
                  )}
                  {!raiseInvoiceHourlyRateLoading && (
                    <>
                      <div
                        className="SubTitle"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      >
                        {" "}
                        Hourly Rate{" "}
                      </div>
                      <HourlyRateTable
                        jsonData={[]}
                        headerMap={headerMapHourly}
                        handleRowEdit={() => {}}
                        setRowData={setHourlyRateTableRows}
                        rowData={hourlyRateTableRows}
                        disabled={allDisabled}
                      />
                    </>
                  )}
                </Grid>
                <Grid item md={4} xs={4}>
                  {raiseInvoiceAssignedBillingLoading && (
                    <Box
                      sx={{
                        m: "auto",
                        width: "5%",
                        p: 5,
                      }}
                    >
                      <CircularProgress></CircularProgress>
                    </Box>
                  )}
                  {!raiseInvoiceAssignedBillingLoading && (
                    <>
                      <div
                        className="SubTitle"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      >
                        Assigned Billing{" "}
                      </div>
                      <AssignedBillingTable
                        jsonData={[]}
                        headerMap={headerMapBilling}
                        handleRowEdit={() => {}}
                        setRowData={setBillingTableRows}
                        rowData={billingTableRows}
                        disabled={allDisabled}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              {raiseInvoiceDetails && raiseInvoiceDetails.jobNumber && (
                <>
                  <Grid container spacing={4} padding={4}>
                    <Grid item md={12} xs={12}>
                      <div className="SubTitle" style={{ marginTop: "0px" }}>
                        {" "}
                        Log Book{" "}
                      </div>
                      <div className="CommentsArea card ">
                        <Grid container>
                          <Grid item xs={2} className="flex-input">
                            <p className="dataTitleV2 mr20 ml20">Status: </p>
                            <div className="logBookDropdown">
                              <DropdownLogbook
                                options={status_type_options}
                                selectedValue={status}
                                onValueChange={handleStatusChange}
                              />
                            </div>
                          </Grid>
                          <Grid item md={2} xs={3} className="flex-input">
                            <p className="dataTitleV2 mr20 ml20">Activity: </p>
                            <div className="logBookDropdown">
                              <DropdownLogbook
                                options={activityOptions}
                                selectedValue={activity}
                                onValueChange={handleActivityChange}
                              />
                            </div>
                          </Grid>
                          <Grid item md={6} xs={6} className="flex-input">
                            <p className="dataTitleV2 mr20 ml20">Comment: </p>
                            <div className="logBookDropdown fullWidth">
                              <TextField
                                fullWidth
                                multiline
                                value={formik.values.comment}
                                type="text"
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "comment",
                                    e?.target?.value
                                  )
                                }
                                rows={1}
                                placeholder="Comment"
                                sx={textfeildStyle}
                                //   textareaStyle={textfeildStyle}
                                inputProps={{ style: { margin: 0 } }}
                                // error={Boolean(touched.comment && errors.comment)}
                              />
                            </div>
                          </Grid>
                          <Grid item md={2} className="flex-input">
                            <div
                              className="button-primary ml20 logBookDropdown"
                              onClick={() => onPostCall()}
                            >
                              Post
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container padding={4}>
                    <Grid item md={12}>
                      <div id="Logbook" className={styles.LogBook}>
                        <div className={styles.LogBookTable}>
                          <table>
                            <tbody>
                              {isinvoiceLogBookLoading
                                ? Array(8)
                                    .fill()
                                    .map((_, index) => (
                                      <tr className={styles.LogRow} key={index}>
                                        <td
                                          className={styles.LogStatus}
                                          style={{ marginLeft: "15px" }}
                                        >
                                          <Skeleton
                                            animation="wave"
                                            variant="rounded"
                                            width={108}
                                            height={25}
                                          />
                                        </td>
                                        <td
                                          className={`${styles.LogActivity}`}
                                          style={{ marginLeft: "-220px" }}
                                        >
                                          <Skeleton
                                            animation="wave"
                                            variant="rounded"
                                            width={120}
                                            height={20}
                                          />
                                        </td>
                                        <td
                                          className={`${styles.LogComments} ${styles.wide}`}
                                          style={{ marginLeft: "-130px" }}
                                        >
                                          <Skeleton
                                            animation="wave"
                                            variant="rounded"
                                            width={500}
                                            height={20}
                                          />
                                        </td>
                                        <td
                                          className={styles.LogDescription}
                                          style={{ marginLeft: "30px" }}
                                        >
                                          <Skeleton
                                            animation="wave"
                                            variant="rounded"
                                            width={300}
                                            height={20}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                : invLogbookData &&
                                  invLogbookData?.map((log) => (
                                    <tr className={styles.LogRow} key={log.id}>
                                      <td
                                        className={`${styles.InvoiceBubble} ${styles.LogStatus}`}
                                      >
                                        INVOICE
                                      </td>
                                      <td
                                        className={`${styles.LogActivity} ${styles.wide}`}
                                      >
                                        {log.activity_type}
                                      </td>
                                      <td
                                        className={`${styles.LogComments} ${styles.wide}`}
                                      >
                                        {log.comments}
                                      </td>
                                      <td
                                        className={`${styles.LogDescription}`}
                                      >
                                        {log.description}
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </div>
        </FormikProvider>
      )}
      {paidPopUp && (
        <InvoicePaidPopUp
          phaseTableRows={phaseTableRows}
          isVisible={paidPopUp}
          onClose={() => showPaidPopUp(false)}
          jobNumber={jobNumber}
          invoiceNumber={invoiceNumber}
          logValues={formik.values}
          activity={activity}
          status={status}
        />
      )}
    </>
  );
}
export default RaiseInvoice;

const InvoiceSchema = Yup.object().shape({
  invoiceDate: Yup.string().required(),
  invoiceType: Yup.string().required(),
  paymentTerms: Yup.string().required(),
  address: Yup.string().required(),
  comment: Yup.string().required(),
});

const invoiceTypes = [
  {
    label: "Invoice",
    value: "invoice",
  },
  {
    label: "Credit",
    value: "credit",
  },
];

const paymentTerms = [
  {
    label: "14 Days",
    value: "1",
  },
  {
    label: "Payment on Appointment",
    value: "2",
  },
];

const headerMapPhase = {
  phaseID: "",
  phaseName: "Phase Name",
  phaseAmount: "Phase Amt",
  invoicedAmount: "Inv Amt",
  vat: "Vat",
  vatAmount: "Vat Amount",
  grossTotal: "Gross Total",
  nominal: "Nominal",
  comment: "Comment",
};

const headerMapHourly = {
  ID: "",
  fullName: "Full Name",
  entryDate: "Entry Date",
  quantity: "Qty",
  chargeRate: "Charge Rate (£)",
  netTotal: "Net Total",
  comment: "Comment",
};

const headerMapBilling = {
  plannedInvoiceID: "",
  plannedDate: "Planned Date",
  plannedAmount: "Planned Amount",
  comment: "Comment",
};
