import React, { useState, useRef, useEffect, useContext } from "react";
import cloud from "../../assets/cloud.svg";
import styles from "./TableV2.module.css";
import MoreOptionsButton from "../MoreOptions/MoreOptions.js";
import AdminContext from "../../Context/adminContext";
import EditIcon from "../../assets/Edit.svg";
import NumberScroller from "../NumberScroller/NumberScroller.js";
import ProgressBar from "../ProgressBar/ProgressBar.js";

function Table({
  data,
  clickableRowAdd = false,
  indexPassed = false,
  handleButtonClick = "",
  handleRowEdit = false,
  handleRowDelete = false,
  handleRowArchive = false,
  Resizable = false,
  MaxWidthTable = true,
  editable = false,
  EditData = false,
}) {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [sortedRows, setSortedRows] = useState([]);
  const admin = useContext(AdminContext);
  const MONTHS = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  let totalInvReqFee = 0;

  const toggleSection = () => {
    setSectionVisible(!isSectionVisible);
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const handleRowClick = (firstColumnValue) => {
    if (typeof clickableRowAdd === "string") {
      window.location.href = `${clickableRowAdd}#${firstColumnValue}`;
    }
  };

  useEffect(() => {
    setSortedRows(data.rows);
  }, [data.rows]);

  const sortRows = (index) => {
    let key = index;
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const newSortedRows = [...sortedRows].sort((a, b) => {
      let valA = a[index];
      let valB = b[index];

      if (valA === null || valB === null) return 0; // Skip null values

      // Convert "dd/mm/yy" format date strings to Date objects for comparison
      const dateRegex = /^(\d{2})\/(\d{2})\/(\d{2})$/;
      const dRegex = /^\d{1,2}-[a-zA-Z]{3}-\d{2}$/;
      if (typeof valA === "string" && dateRegex.test(valA)) {
        const [dayA, monthA, yearA] = valA.split("/").map(Number);
        valA = new Date(yearA + 2000, monthA - 1, dayA); // Assuming 'yy' is 2000s
        const [dayB, monthB, yearB] = valB.split("/").map(Number);
        valB = new Date(yearB + 2000, monthB - 1, dayB);
      }
      if (typeof valA === "string" && dRegex.test(valA)) {
        const [dayA, monthA, yearA] = valA.split("-");
        valA = new Date(Number(yearA) + 2000, MONTHS[monthA] - 1, Number(dayA));

        const [dayB, monthB, yearB] = valB.split("-");
        valB = new Date(Number(yearB) + 2000, MONTHS[monthB] - 1, Number(dayB));
      }

      // Continue with your existing comparison logic
      // If values are Date objects, this will correctly compare their timestamps
      return (valA < valB ? -1 : 1) * (direction === "ascending" ? 1 : -1);
    });

    setSortConfig({ key, direction });
    setSortedRows(newSortedRows);
  };

  const [colWidths, setColWidths] = useState({});
  const [totalTableWidth, setTotalTableWidth] = useState(0);
  const draggingRef = useRef(null);
  const initialX = useRef(0);

  useEffect(() => {
    // Get the window width or any maximum width you want for your table
    const maxTableWidth = MaxWidthTable;

    // Divide equally among the columns
    const initialWidth = maxTableWidth / data.headers.length;

    console.log("maxTableWidth: ", MaxWidthTable);

    const initialWidths = {};
    data.headers.forEach((header, index) => {
      if (indexPassed & (index == 0)) {
        initialWidths[`col${index}`] = 0;
      } else {
        initialWidths[`col${index}`] = initialWidth;
      }
    });

    setColWidths(initialWidths);
    setTotalTableWidth(maxTableWidth); // Update the total table width as well
  }, [data.headers, MaxWidthTable]);

  useEffect(() => {
    const totalWidth = Object.values(colWidths).reduce(
      (acc, val) => acc + val,
      0
    );
    setTotalTableWidth(totalWidth);
  }, [colWidths, MaxWidthTable]);

  // useEffect(() => {
  //   console.log('DAAAAAAAAAAAAATAAAAAAAAAA: ', data)
  // }, [data]);

  const handleMouseDown = (e, col) => {
    e.preventDefault();
    initialX.current = e.clientX;
    draggingRef.current = col;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (draggingRef.current) {
      const ratio =
        totalTableWidth / (totalTableWidth / (data.headers.length - 1));
      const delta = (e.clientX - initialX.current) * ratio;
      setColWidths((prevWidths) => {
        const colIndex = parseInt(draggingRef.current.replace("col", ""), 10);
        const adjacentCol = `col${colIndex + 1}`;

        const newWidth = Math.max(50, prevWidths[draggingRef.current] + delta);
        const adjacentNewWidth = Math.max(50, prevWidths[adjacentCol] - delta);
        let updatedWidths = { ...prevWidths };

        if (newWidth > 50 && adjacentNewWidth > 50) {
          updatedWidths[draggingRef.current] = newWidth;
          if (updatedWidths[adjacentCol]) {
            updatedWidths[adjacentCol] = adjacentNewWidth;
          }
        } else if (newWidth > 50 && adjacentNewWidth === 50 && delta < 0) {
          // Allow shrinking the dragging column even if the adjacent column is at its minimum
          updatedWidths[draggingRef.current] = newWidth;
        } else if (newWidth === 50 && adjacentNewWidth > 50 && delta > 0) {
          // Allow expanding the adjacent column even if the dragging column is at its minimum
          if (updatedWidths[adjacentCol]) {
            updatedWidths[adjacentCol] = adjacentNewWidth;
          }
        }

        return updatedWidths;
      });
      initialX.current = e.clientX;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    draggingRef.current = null;
  };

  const getStyle = (Resizable, colWidths, indexv2) => {
    const baseStyle = { whiteSpace: "nowrap", position: "relative" };
    if (Resizable) {
      return { ...baseStyle, width: `${colWidths[`col${indexv2}`]}px` };
    }
    return baseStyle;
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    // Replace any non-digit characters with an empty string
    const numbersOnly = value.replace(/\D/g, "");
    event.target.value = numbersOnly;
  };

  return (
    <div className={styles.tableContainer}>
      <div style={{ backgroundColor: "#ffffff" }}>
        <table className={styles.table}>
          <thead className={styles.theTablesHead}>
            <tr className={styles.tableHeader}>
              {data.headers.map((header, index) => {
                let indexv2 = index;
                if (indexPassed) {
                  indexv2 += 1;
                }

                // Check if the current column is being sorted
                let arrow;
                if (sortConfig.key === indexv2) {
                  // Choose the appropriate arrow based on the sorting direction
                  arrow = sortConfig.direction === "ascending" ? " ▲" : " ▼";
                }

                // <th
                // key={index}
                // style={{ width: `${colWidths[`col${index}`]}px`, whiteSpace: 'nowrap', position: 'relative' }}
                // className={stilies.tableHeaders}
                // >
                //   <div>{header}</div>
                //   {Resizable && (<div style={{ position: 'absolute', right: -4, top: -3, width: '8px', height: '117%', backgroundColor: 'RGBA(55, 55, 55, 0.5)', cursor: 'ew-resize' }} onMouseDown={(e) => handleMouseDown(e, `col${index}`)}></div>)}
                // </th>

                if ((data.dataName == "OpenSicknessData") & (index == 4)) {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={{
                        width: `${colWidths[`col${indexv2}`]}px`,
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div
                        className={styles.CenterContent}
                        style={{ marginLeft: "calc(12%)" }}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if (
                  (data.dataName == "SicknessLogProfileData") &
                  (index == 4)
                ) {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={{
                        width: `${colWidths[`col${indexv2}`]}px`,
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div
                        className={styles.CenterContent}
                        style={{ marginLeft: "calc(12%)" }}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if (
                  (data.dataName == "SicknessLogData") &
                  (index == 5)
                ) {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={{
                        width: `${colWidths[`col${indexv2}`]}px`,
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div
                        className={styles.CenterContent}
                        style={{ marginLeft: "calc(12%)" }}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if (
                  (data.dataName == "ReviewFormsAndFeedbackProfileData") &
                  (index == 2)
                ) {
                  return (
                    <th key={index} className={styles.th}>
                      <div
                        className={styles.CenterContent}
                        style={{ marginLeft: "calc(12%)" }}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if ((data.dataName == "OpenReviews") & (index == 3)) {
                  return (
                    <th key={index} className={styles.th}>
                      <div
                        className={styles.CenterContent}
                        style={{ marginLeft: "calc(12%)" }}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if (
                  (data.dataName == "OutstandingDocuments") &
                  (index == 3)
                ) {
                  return (
                    <th key={index} className={styles.th}>
                      <div
                        className={styles.CenterContent}
                        style={{ marginLeft: "calc(12%)" }}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if (
                  (data.dataName == "ReviewLogTableData") &
                  (index == 5)
                ) {
                  return (
                    <th key={index} className={styles.th}>
                      <div
                        className={styles.CenterContent}
                        style={{ marginLeft: "calc(12%)" }}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if (
                  (data.dataName == "ReviewLogTableData") &
                  (index == 4)
                ) {
                  return (
                    <th key={index} className={styles.th}>
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if ((data.dataName == "SuppliersData") & (index == 7)) {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={{
                        width: `${colWidths[`col${index}`]}px`,
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if ((data.dataName == "InvoicingData") & (index == 0)) {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={{
                        width: "1px",
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                    </th>
                  );
                } else if ((data.dataName == "InvoicingData") & (index == 1)) {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={{
                        width: "0px",
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                    </th>
                  );
                } else if ((data.dataName == "InvoicingData") & (index == 2)) {
                  return (
                    <th
                      key={index}
                      className={`${styles.th} ${styles.largerColumn}`}
                      style={{
                        width: "0px",
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div onClick={() => sortRows(indexv2)}>
                        {header}
                        {arrow}
                      </div>
                    </th>
                  );
                } else if (
                  (data.dataName == "InvoicingData") &
                  (index == 3 || index == 6)
                ) {
                  return (
                    <th
                      key={index}
                      className={`${styles.th} ${styles.smallerColumn}`}
                      style={{
                        width: "0px",
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                    </th>
                  );
                } else if (
                  (data.dataName == "SuppliersData") &
                  (index == 3 ||
                    index == 4 ||
                    index == 5 ||
                    index == 6 ||
                    index == 7 ||
                    index == 8)
                ) {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={{
                        width: `${colWidths[`col${index}`]}px`,
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if (
                  (data.dataName == "ClientProjects") &
                  (index == 0 ||
                    index == 2 ||
                    index == 3 ||
                    index == 4 ||
                    index == 5 ||
                    index == 6 ||
                    index == 7 ||
                    index == 8 ||
                    index == 9)
                ) {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={{
                        width: `${colWidths[`col${index}`]}px`,
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    >
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -4,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if (
                  data.dataName === "TimesheetTrainingData" &&
                  (index === 0 || index === 2)
                ) {
                  return (
                    <th key={index} className={`${styles.th}`}>
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                    </th>
                  );
                } else if (
                  data.dataName === "TimesheetData" &&
                  (data.mode === "Summary - Phase" ||
                    data.mode === "Summary - Person") &&
                  (index === 1 || index === 2)
                ) {
                  return (
                    <th key={index} className={`${styles.th}`}>
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                    </th>
                  );
                } else if (
                  data.dataName === "TimesheetData" &&
                  (data.mode === "Individual" || data.mode === "Pending") &&
                  (index === 1 || index === 5 || index === 6 || index === 7)
                ) {
                  return (
                    <th key={index} className={`${styles.th}`}>
                      <div
                        className={styles.CenterContent}
                        onClick={() => sortRows(indexv2)}
                      >
                        {header}
                        {arrow}
                      </div>
                    </th>
                  );
                } else if (
                  data.dataName === "TimesheetData" &&
                  (data.mode === "Individual" || data.mode === "Pending") &&
                  (index === 0 || index === 2 || index === 3)
                ) {
                  return (
                    <th
                      key={index}
                      className={`${styles.th}`}
                      style={getStyle(Resizable, colWidths, indexv2)}
                    >
                      <div onClick={() => sortRows(indexv2)}>
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -1,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else if (
                  data.dataName === "TimesheetData" &&
                  (data.mode === "Individual" || data.mode === "Pending") &&
                  index === 4
                ) {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={getStyle(Resizable, colWidths, indexv2)}
                    >
                      <div onClick={() => sortRows(indexv2)}>
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -1,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                } else {
                  return (
                    <th
                      key={index}
                      className={styles.th}
                      style={getStyle(Resizable, colWidths, indexv2)}
                    >
                      <div onClick={() => sortRows(indexv2)}>
                        {header}
                        {arrow}
                      </div>
                      {Resizable && (
                        <div
                          style={{
                            position: "absolute",
                            right: -1,
                            top: -3,
                            width: "8px",
                            height: "117%",
                            backgroundColor: "RGBA(55, 55, 55, 0.5)",
                            cursor: "ew-resize",
                          }}
                          onMouseDown={(e) => handleMouseDown(e, `col${index}`)}
                        ></div>
                      )}
                    </th>
                  );
                }
              })}
            </tr>
            <div className={styles.headerBorderStyle}></div>
          </thead>
          <tbody className={styles.tbody}>
            {sortedRows.length === 0 ? ( // check for missing information
              <tr>
                <td colSpan={8} className={styles.tdNoInfo}>
                  No information on record.
                </td>
              </tr>
            ) : (
              sortedRows.map((row, index) => {
                let indexv2 = index;
                if (indexPassed) {
                  indexv2 += 1;
                }

                // Conditionally apply the highlight class based on clickableRowAdd
                const rowClasses = `${styles.tableRow} ${
                  clickableRowAdd ? styles.rowHoverHighlight : ""
                }`;

                return (
                  <tr
                    key={index}
                    className={rowClasses}
                    onClick={() => clickableRowAdd && handleRowClick(row[0])}
                  >
                    {row.map((cell, cellIndex) => {
                      // Add to the total fee only if dataName is 'InvoicingData'
                      if (
                        (data.dataName === "InvoicingData") &
                        (cellIndex == 3)
                      ) {
                        totalInvReqFee += parseFloat(
                          cell.replace("£", "").replace(",", "")
                        ); // Assuming fee is in the 4th column (index 3)
                      }

                      // console.log('row[index][0]: ', row[0]);
                      if (indexPassed & (cellIndex == 0)) {
                        return null;
                      }
                      if (
                        indexPassed &
                        (data.dataName == "OpenSicknessData") &
                        (cellIndex == 1)
                      ) {
                        return null;
                      } else if (
                        cell === "Edit" &&
                        cellIndex === 1 &&
                        editable &&
                        data.dataName == "OpenReviews"
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            className={styles.tdImage}
                            onClick={handleRowEdit(EditData, 2, index)}
                          >
                            <img
                              className={styles.trEditIcon}
                              src={EditIcon}
                              alt="EditIcon"
                            />
                          </td>
                        );
                      } else if (
                        data.dataName === "InvoicingData" &&
                        (cellIndex === 0) & admin
                      ) {
                        return (
                          <MoreOptionsButton
                            key={cellIndex}
                            onEdit={handleRowEdit(data, row[0], index)}
                          />
                        );
                      } else if (
                        data.dataName === "InvoicingData" &&
                        (cellIndex === 0) & !admin
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                            }}
                          >
                            <div className={styles.CenterContent}>{}</div>
                          </td>
                        );
                      } else if (
                        data.dataName === "InvoicingData" &&
                        (cellIndex === 1 || cellIndex === 3)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                            }}
                          >
                            <div className={styles.CenterContent}>{cell}</div>
                          </td>
                        );
                      } else if (
                        data.dataName === "InvoicingData" &&
                        (cellIndex === 2 || cellIndex === 5 || cellIndex === 7)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "PT Sans, sans-serif",
                              whiteSpace: "unset",
                              width: "150px",
                            }}
                            className={`${styles.td}`}
                          >
                            {cell}
                          </td>
                        );
                      } else if (
                        data.dataName === "InvoicingData" &&
                        cellIndex === 6
                      ) {
                        const cellDate = new Date(cell);
                        const currentDate = new Date();
                        currentDate.setHours(0, 0, 0, 0); // Reset time to compare only the date

                        const isBeforeCurrentDate = cellDate < currentDate;
                        const formattedCell = `${String(
                          cellDate.getDate()
                        ).padStart(2, "0")}/${String(
                          cellDate.getMonth() + 1
                        ).padStart(2, "0")}/${String(
                          cellDate.getFullYear()
                        ).substring(2)}`;

                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                              color: isBeforeCurrentDate ? "red" : "black",
                            }}
                            className={styles.td}
                          >
                            <div className={styles.CenterContent}>
                              {formattedCell}
                            </div>
                          </td>
                        );
                      } else if (
                        data.dataName === "TimesheetData" &&
                        (data.mode === "Summary - Phase" ||
                          data.mode === "Summary - Person") &&
                        (cellIndex === 1 || cellIndex === 2)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                            }}
                          >
                            <div className={styles.CenterContent}>{cell}</div>
                          </td>
                        );
                      } else if (
                        data.dataName === "TimesheetData" &&
                        (data.mode === "Individual" ||
                          data.mode === "Pending") &&
                        (cellIndex === 1 ||
                          cellIndex === 5 ||
                          cellIndex === 6 ||
                          cellIndex === 7)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                            }}
                          >
                            <div className={styles.CenterContent}>{cell}</div>
                          </td>
                        );
                      } else if (
                        data.dataName === "TimesheetTrainingData" &&
                        cellIndex === 2
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                            }}
                          >
                            <div className={styles.CenterContent}>{cell}</div>
                          </td>
                        );
                      } else if (
                        data.dataName === "TimesheetTrainingData" &&
                        cellIndex === 0
                      ) {
                        const cellDate = new Date(cell);
                        const currentDate = new Date();
                        currentDate.setHours(0, 0, 0, 0); // Reset time to compare only the date

                        const formattedCell = `${String(
                          cellDate.getDate()
                        ).padStart(2, "0")}/${String(
                          cellDate.getMonth() + 1
                        ).padStart(2, "0")}/${String(
                          cellDate.getFullYear()
                        ).substring(2)}`;

                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                            }}
                            className={styles.td}
                          >
                            <div className={styles.CenterContent}>
                              {formattedCell}
                            </div>
                          </td>
                        );
                      } else if (
                        data.dataName === "InvoicingData" &&
                        [2, 5, 7].includes(cellIndex)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                            }}
                            className={styles.td}
                          >
                            {cell}
                          </td>
                        );
                      } else if (
                        data.dataName === "InvoicingData" &&
                        (cellIndex === 8) & admin
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                              marginRight: "24px",
                              marginBottom: "10px",
                              marginTop: "5px",
                            }}
                            className={styles.RightAlign}
                          >
                            <div
                              className={styles.InvoicingReq_Done}
                              onClick={() => handleButtonClick(row[0])}
                            >
                              DONE
                            </div>
                          </td>
                        );
                      } else if (
                        data.dataName === "InvoicingData" &&
                        (cellIndex === 8) & !admin
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              whiteSpace: "unset",
                              marginRight: "24px",
                            }}
                            className={styles.RightAlign}
                          ></td>
                        );
                      } else if (
                        data.dataName === "ClientsData" &&
                        cellIndex === 1
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              maxWidth: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            className={styles.td}
                          >
                            {cell}
                          </td>
                        );
                      } else if (
                        data.dataName === "ClientsData" &&
                        cellIndex === 4
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              maxWidth: "400px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            className={styles.td}
                          >
                            {cell}
                          </td>
                        );
                      } else if (
                        data.dataName === "ClientsData" &&
                        cellIndex === 5
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              width: "500px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            className={styles.td}
                          >
                            {cell}
                          </td>
                        );
                      } else if (
                        data.dataName === "ClientsData" &&
                        cellIndex === 7
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              minWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            className={styles.td}
                          >
                            {cell}
                          </td>
                        );
                      } else if (
                        (data.dataName == "SuppliersData") &
                        (cellIndex == 0) &
                        admin
                      ) {
                        return (
                          <MoreOptionsButton
                            key={cellIndex}
                            onEdit={handleRowEdit(data, row[0], index)}
                            onArchive={handleRowArchive(data, row[0], index)}
                          />
                        );
                      } else if (
                        (data.dataName == "SuppliersData") &
                        (cellIndex == 0) &
                        !admin
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className="ellipsis-text"
                              style={{
                                width: `${colWidths[`col${indexv2}`]}px`,
                              }}
                            />
                          </td>
                        );
                      } else if (
                        (data.dataName == "SuppliersData") &
                        (cellIndex == 6) &
                        (cell != "")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={styles.CenterContent}>{cell}%</div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SuppliersData") &
                        (cellIndex == 7) &
                        (cell != [])
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={styles.CenterContent}>
                              {cell.join(", ")}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SuppliersData") &
                        (cellIndex == 8) &
                        (cell == "Yes *")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={styles.SuppliersListData_Yes}>
                              {cell}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SuppliersData") &
                        (cellIndex == 8) &
                        (cell == "Don't Use")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={styles.SuppliersListData_No}>
                              {cell}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SuppliersData") &
                        (cellIndex == 3 ||
                          cellIndex == 4 ||
                          cellIndex == 5 ||
                          cellIndex == 6 ||
                          cellIndex == 7 ||
                          cellIndex == 8)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={styles.CenterContent}>{cell}</div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ClientProjects") &
                        (cellIndex == 2)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={`${styles.CenterContent}`}>
                              {cell &&
                              (Array.isArray(cell) ? cell : cell.split(", "))
                                .length > 0 ? (
                                <div style={{ display: "flex" }}>
                                  {(Array.isArray(cell)
                                    ? cell
                                    : cell.split(", ")
                                  ).map((tag, index) => {
                                    const abbreviateTag = tag
                                      .split(" ")
                                      .slice(0, 2)
                                      .map((word) => word[0].toUpperCase())
                                      .join("");
                                    return (
                                      <div
                                        className={styles.Tag}
                                        key={index}
                                        style={{ marginRight: "3px" }}
                                      >
                                        {abbreviateTag}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <div style={{ height: "25px" }}></div>
                              )}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ClientProjects") &
                        (cellIndex == 3)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={`${styles.CenterContent} ${
                                cell === "Live"
                                  ? styles.StatusLive
                                  : cell === "Complete"
                                  ? styles.StatusLive
                                  : cell === "Oh-hold"
                                  ? styles.StatusOnHold
                                  : cell === "Opp"
                                  ? styles.StatusOpp
                                  : styles.StatusDormant
                              }`}
                            >
                              {cell}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ClientProjects") &
                        (cellIndex == 5)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={`${styles.CenterContent}`}>
                              {cell !== "" && !isNaN(parseFloat(cell))
                                ? `${Math.round(parseFloat(cell) * 100)}%`
                                : ""}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ClientProjects") &
                        (cellIndex == 6)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              width: "75px",
                            }}
                            className={styles.td}
                          >
                            <div className={`${styles.CenterContent}`}>
                              <ProgressBar
                                currentValue={parseFloat(cell)}
                                maxValue={parseFloat(row[5])} // Accessing the cell value at index 5 (pFB)
                                displayMode={"valuePercent"}
                                greenThreshold={70}
                                yellowThreshold={100}
                                orangeThreshold={120}
                              />
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ClientProjects") &
                        (cellIndex == 7)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{
                              fontFamily: "'PT Sans', sans-serif",
                              width: "75px",
                            }}
                            className={styles.td}
                          >
                            <div className={`${styles.CenterContent}`}>
                              <ProgressBar
                                currentValue={parseFloat(cell)}
                                maxValue={parseFloat(row[8])}
                                displayMode={"percentage"}
                                variant={"blue"}
                              />
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ClientProjects") &
                        (cellIndex == 8)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={`${styles.CenterContent}`}>
                              {`£${Number(cell).toLocaleString("en-GB", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}`}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ClientProjects") &
                        (cellIndex == 9)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={`${styles.CenterContent}`}>
                              {cell && cell !== ""
                                ? new Date(cell).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "2-digit",
                                  })
                                : ""}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ClientProjects") &
                        (cellIndex == 0 || cellIndex == 4 || cellIndex == 5)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div className={`${styles.CenterContent}`}>
                              {cell}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ReviewLogTableData") &
                        (cellIndex == 6) &
                        (cell == "View")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ marginLeft: "calc(12%)" }}
                            >
                              <div
                                className={styles.ReviewsViewButton}
                                onClick={() =>
                                  handleButtonClick(row[0], row[1])
                                }
                              >
                                VIEW
                              </div>
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "end_Of_Year_Adjustment_Rows") &
                        (cellIndex == 3 || cellIndex == 6)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ marginLeft: "calc(12%)" }}
                            >
                              <input
                                className={styles.numberInput}
                                type="text"
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ReviewLogTableData") &
                        (cellIndex == 6) &
                        (cell == "NoView")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ marginLeft: "calc(12%)" }}
                            >
                              <div className={styles.ReviewsNoViewButton}>
                                VIEW
                              </div>
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ReviewLogTableData") &
                        (cellIndex == 5) &
                        (cell != "COMPLETED")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ color: "#F37021", fontWeight: "13px" }}
                            >
                              {cell}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ReviewLogTableData") &
                        (cellIndex == 5) &
                        (cell == "COMPLETED")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ color: "#0B8A00", fontWeight: "13px" }}
                            >
                              {cell}
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ReviewFormsAndFeedbackProfileData") &
                        (cellIndex == 3) &
                        (cell == "TO DO")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ marginLeft: "calc(12%)" }}
                            >
                              <div
                                className={styles.ReviewsTODOButton}
                                onClick={() =>
                                  handleButtonClick(row[0], row[1])
                                }
                              >
                                TO DO
                              </div>
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "OpenReviews") &
                        (cellIndex == 4) &
                        (cell == "START")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ marginLeft: "calc(12%)" }}
                            >
                              <div
                                className={styles.ReviewsTODOButton}
                                onClick={() =>
                                  handleButtonClick(row[0], row[1])
                                }
                              >
                                START
                              </div>
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "OutstandingDocuments") &
                        (cellIndex == 4) &
                        (cell == "CHASE")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ marginLeft: "calc(12%)" }}
                            >
                              <div
                                className={styles.ReviewsTODOButton}
                                onClick={() =>
                                  handleButtonClick(
                                    row[0],
                                    row[1],
                                    row[2],
                                    row[3]
                                  )
                                }
                              >
                                CHASE
                              </div>
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "OpenReviews") &
                        (cellIndex == 4) &
                        (cell == "RESUME")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ marginLeft: "calc(12%)" }}
                            >
                              <div
                                className={styles.ReviewsDRAFTButton}
                                onClick={() =>
                                  handleButtonClick(row[0], row[1])
                                }
                              >
                                RESUME
                              </div>
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ReviewFormsAndFeedbackProfileData") &
                        (cellIndex == 3) &
                        (cell == "DRAFT")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className={styles.CenterContent}
                              style={{ marginLeft: "calc(12%)" }}
                            >
                              <div
                                className={styles.ReviewsDRAFTButton}
                                onClick={() =>
                                  handleButtonClick(row[0], row[1])
                                }
                              >
                                DRAFT
                              </div>
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "OpenSicknessData") &
                        (cellIndex == 6)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.CenterContent}
                          >
                            <div
                              style={{ marginLeft: "calc(12%)" }}
                              className={styles.OpenSicknessData_Return}
                              onClick={() => handleButtonClick(row[0])}
                            >
                              RETURNED
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SicknessLogProfileData") &
                        (cellIndex == 5) &
                        (cell == "NO")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.CenterContent}
                          >
                            <div
                              style={{ marginLeft: "calc(12%)" }}
                              className={styles.OpenSicknessData_Return}
                              onClick={() => handleButtonClick(row[0])}
                            >
                              TO DO
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SicknessLogProfileData") &
                        (cellIndex == 5) &
                        (cell == "YES" || cell == "SIGN OFF")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              style={{ marginLeft: "calc(12%)" }}
                              className={styles.SicknessLogData_Yes}
                            >
                              COMPLETED
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SicknessLogProfileData") &
                        (cellIndex == 5) &
                        (cell == "N/A")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              style={{ marginLeft: "calc(12%)" }}
                              className={styles.SicknessLogData_Yes}
                            >
                              N/A
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SicknessLogProfileData") &
                        (cellIndex == 5) &
                        (cell == "DRAFT")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.CenterContent}
                          >
                            <div
                              style={{
                                marginLeft: "calc(12%)",
                                justifyContent: "center",
                              }}
                              className={styles.OpenSicknessData_Return}
                              onClick={() => handleButtonClick(row[0])}
                            >
                              DRAFT
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SicknessLogData") &
                        (cellIndex == 6) &
                        (cell == "SIGN OFF")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.CenterContent}
                          >
                            <div
                              style={{ marginLeft: "calc(12%)" }}
                              className={styles.SicknessLogData_SignOff}
                              onClick={() => handleButtonClick(row[0])}
                            >
                              SIGN OFF
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SicknessLogData") &
                        (cellIndex == 6) &
                        (cell == "YES")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              style={{ marginLeft: "calc(12%)" }}
                              className={styles.SicknessLogData_Yes}
                            >
                              YES
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SicknessLogData") &
                        (cellIndex == 6) &
                        (cell == "NO" || cell == "DRAFT")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              style={{ marginLeft: "calc(12%)" }}
                              className={styles.SicknessLogData_No}
                            >
                              NO
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "SicknessLogData") &
                        (cellIndex == 6) &
                        (cell == "N/A")
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              style={{
                                marginLeft: "calc(12%)",
                                color: "#0B8A00",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              N/A
                            </div>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ProfileSicknessDocuments") &
                        (cellIndex == 3)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <a
                              style={{ marginLeft: "calc(12%)" }}
                              href={cell}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={cloud} alt="Cloud" />
                            </a>
                          </td>
                        );
                      } else if (
                        (data.dataName == "ProfileSicknessDocuments") &
                        (cellIndex == 0)
                      ) {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div style={{ width: `55px` }}>{cell}</div>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={cellIndex}
                            style={{ fontFamily: "'PT Sans', sans-serif" }}
                            className={styles.td}
                          >
                            <div
                              className="ellipsis-div"
                              style={{
                                width: Resizable
                                  ? `${colWidths[`col${indexv2}`]}px`
                                  : "",
                              }}
                            >
                              {cell}
                            </div>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })
            )}

            {data.dataName === "InvoicingData" && (
              <tr className={styles.tableRow} style={{ background: "#EAF2F9" }}>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
                <td
                  className={styles.td}
                  style={{
                    fontFamily: "'PT Sans', sans-serif",
                    whiteSpace: "unset",
                    fontWeight: "700",
                  }}
                >
                  <div className={styles.RightAlign}>Total</div>
                </td>
                <td
                  className={styles.td}
                  style={{
                    fontFamily: "'PT Sans', sans-serif",
                    whiteSpace: "unset",
                    fontWeight: "700",
                  }}
                >
                  <div className={styles.CenterContent}>
                    {parseFloat(totalInvReqFee).toLocaleString("en-UK", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0,
                    })}
                  </div>
                </td>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;