import React from 'react';
import '../../App.css';
import Dashboard from '../../assets/Dashboard.svg';
import Opportunities from '../../assets/Opportunities.svg';
import Projects from '../../assets/Projects.svg';
import TimeSheets from '../../assets/Time Sheets.svg';
import Clients from '../../assets/Clients.svg';
import Invoicing from '../../assets/Invoicing.svg';
import Suppliers from '../../assets/Suppliers.svg';
import Resourcing from '../../assets/Resourcing.svg';
import Profile from '../../assets/Profile.svg';
import Approvals from '../../assets/Approvals.svg';
import styles from './Sidebarlist.module.css';


export const SidebarData = [
  {
    title: 'Dashboard',
    icon: <img className={styles.SidebarIcons} src={Dashboard} alt='Dashboard' />,
    link: '/dashboard',
    disabled: false,
  },
  {
    title: 'Opportunities',
    icon: <img className={styles.SidebarIcons} src={Opportunities} alt='Projects' />,
    link: '/Opportunities',
    disabled: false,
  },
  {
    title: 'Projects',
    icon: <img className={styles.SidebarIcons} src={Projects} alt='Projects' />,
    link: '/projects',
    disabled: false,
  },
  {
    title: 'Time Sheets',
    icon: <img className={styles.SidebarIcons} src={TimeSheets} alt='TimeSheets' />,
    link: '/timesheets',
    disabled: false,
  },
  {
    title: 'Clients',
    icon: <img className={styles.SidebarIcons} src={Clients} alt='Clients' />,
    link: '/clients',
    disabled: false,
  },
  {
    title: 'Invoicing',
    icon: <img className={styles.SidebarIcons} src={Invoicing} alt='Invoicing' />,
    link: '/invoicing',
    disabled: false,
  },
  {
    title: 'Suppliers',
    icon: <img className={styles.SidebarIcons} src={Suppliers} alt='Suppliers' />,
    link: '/suppliers',
    disabled: false,
  },
  {
    title: 'Resourcing',
    icon: <img className={styles.SidebarIcons} src={Resourcing} alt='Resourcing' />,
    link: '/resourcing',
    disabled: true,
  },
  {
    title: 'Profile',
    icon: <img className={styles.SidebarIcons} src={Profile} alt='Profile' />,
    link: '/Profile',
    disabled: false,
  },
  {
    title: 'Admin',
    icon: <img className={styles.SidebarIcons} src={Approvals} alt='Admin' />,
    link: '/Admin',
    disabled: false,
  },
];