import React, { useState, useEffect, useContext } from 'react';
import { setServiceFilter, setSearchValue, setSearchInputValue } from '../../../redux/slices/suppliersdata';

import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import Filter from '../../../Components/Filter/Filter';
import { useSelector, useDispatch } from '../../../redux/store';
import AdminContext from '../../../Context/adminContext';
import SearchBar from '../../../Components/SearchBar/SearchBar';

import ProjectListTable from '../../Projects/ProjectList/ProjectListTable';
import Table from '../../../Components/Table/TableV2';
import EditDiv from '../../../Components/EditDiv/EditDiv';
import Dropdown from "../../../Components/DropDownBox/Dropdown";
import Modal from 'react-modal';

import styles from './Suppliers.module.css';
import buttonStyles from '../../../Components/YesNoButtonsCSS/YesNoButtons.module.css';
import { Box, CircularProgress } from '@mui/material';

function Suppliers() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = accounts[0];

  const admin = useContext(AdminContext);

  const [serviceOptions, setServiceOptions] = useState([]);

  const [fetchedData, setFetchedData] = useState([]);
  const [displayEditDiv, setDisplayEditDiv] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [editType, setEditType] = useState('UPDATE');
  const [archiveData, setArchiveData] = useState('');
  const [rowIndex, setRowIndex] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [currentId, setCurrentId] = useState(null);

  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState(null);
  const [isResponseShown, setIsResponseShown] = useState(false);

  const searchInputValue = useSelector((state) => state.suppliers.searchInputValue);
  const searchValue = useSelector((state) => state.suppliers.searchValue);
  const serviceFilter = useSelector((state) => state.suppliers.serviceFilter)

  const fetchServiceOptions = async () => {
    try {
      const result = await axios.get(`${API_URL}/api/suppliers/serviceOptions`);
      const transformedData = result.data.map(service => service);
      transformedData.unshift('All');
      setServiceOptions(transformedData);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  const fetchData = async (
    searchValue = '',
    serviceFilter = '',
  ) => {
    setLoading(true);

    try {
      const response = await axios.get(`${API_URL}/api/suppliers/pageData?searchValue=${searchValue}&serviceFilter=${serviceFilter}`);

      const data = response.data;
      setFetchedData(data);

    } catch (error) {
      console.error(error);
      throw new Error(`HTTP error! status: ${error.response.status}`);
    } finally {
      setLoading(false);
    }
  };

  const handleServiceFilter = (service) => {
    if (service == "All") {
      dispatch(setServiceFilter(''));
    } else {
      dispatch(setServiceFilter(service));
    }
  };

  const handleSearch = (event) => {
    dispatch(setSearchInputValue(event.target.value));
  };

  const handleSearchSubmit = () => {
    dispatch(setSearchValue(searchInputValue));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const handleAddEdit = (data) => {
    // Clone the data object to avoid mutating original data
    let newData = { ...data };

    // Transform the DataEdit array
    newData.DataEdit = newData.DataEdit.map((item, i) => {
      return {
        ...item,
        dataName: newData.headers[i + 1], // Add 1 to i to skip the first item
        data: newData.rows[0][i + 1] // Empty string
      };
    });
    setCurrentData(newData);
    setEditType('ADD');
    handleEdit(newData)();
    // setDisplayEditDiv(true);
  };

  const handleEdit = (data) => () => {
    setCurrentData(data);
    setDisplayEditDiv(true);
  };

  const handleRowArchive = (data, SQLid) => {
    setRowIndex(SQLid);
    setArchiveData(data);
  };

  const handleRowEdit = (data, SQLid, index) => () => {
    // Clone the data object to avoid mutating original data
    let newData = { ...data };

    // Transform the DataEdit array
    newData.DataEdit = newData.DataEdit.map((item, i) => {
      return {
        ...item,
        dataName: newData.headers[i + 1], // Add 1 to i to skip the first item
        data: newData.rows[index][i + 1] // Same here
      };
    });
    setEditType('UPDATE');
    setRowIndex(SQLid);
    handleEdit(newData)();
  };

  const handleUpdateClick = async (DataTitle, selectedValues) => {
    // console.log('DataTitle, selectedValues', DataTitle, selectedValues)
    try {
      const response = await fetch(`${API_URL}/api/suppliers/UpdatingInformation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Action: editType,
          SectionName: DataTitle,
          UpdatedValues: selectedValues,
          RowID: rowIndex,
        }),
      })

      if (response.ok) {
        const data = await response.json();
        setReload(reload + 1);
        setApiResponse(data.message);
      } else {
        setApiResponse('Error updating supplier (backend ran into a logical issue, please let a DevOps engineer know)');
      }
    } catch (error) {
      console.error("Error in Supplier Info Update:", error);
    } finally {
      setIsResponseShown(true);
    }
  };

  const openModal = (data, SQLid) => () => {
    setText('archive');
    setEditType('ARCHIVE');

    setCurrentId(SQLid);
    setCurrentData(data);

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmitClick = () => {
    closeModal();
    handleRowArchive(currentData, currentId);
    setReload(reload + 1);
  };

  const handleClick = () => {
    if (text === 'archive') {
      handleSubmitClick();
      setEditType('ARCHIVE');
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (editType === 'ARCHIVE' && rowIndex != null) {
      handleUpdateClick(archiveData.DataEditTitle, null);
    }
  }, [archiveData]);

  useEffect(() => {
    fetchServiceOptions();
  }, []);

  useEffect(() => {
    fetchData(searchValue, serviceFilter !== "All" ? serviceFilter : '');
  }, [searchValue, serviceFilter, reload]);


  const customStyles = {
    overlay: {
      zIndex: 10000,
      backgroundColor: 'transparent',
    },
    content: {
      maxHeight: '90%',
      maxWidth: '90%',
      minHeight: '100px',
      minWidth: '200px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#eaf2f9',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
  };

  return (
    <div className={styles.SuppliersMainContent}>
      <div className={styles.SuppliersTitle}>
        Supplier List
      </div>

      <div className={styles.SuppliersTableFilters}>
        <div className={styles.Filters}>
          <div className={styles.ServiceFilter}>
            <label className={styles.FilterLabel} htmlFor='serviceFilter' style={{ fontSize: '12px' }}>Service:</label>
            <div className={styles.dropdownContainer}>
              <Dropdown options={serviceOptions} initialSelectedValue={serviceFilter} onValueChange={handleServiceFilter} options_width='190%' disabled={loading} />
            </div>
          </div>
        </div>

        <div className={styles.SearchBar}>
          {admin && (
            <div className={`${styles.AddBtn} ${loading ? styles.AddDisabled : ''}`} onClick={!loading ? () => handleAddEdit(fetchedData.add) : null}>
              ADD
            </div>
          )}
          <SearchBar value={searchInputValue} onChange={handleSearch} onKeyDown={handleKeyDown} disabled={loading} onClick={handleSearchSubmit} />
        </div>
      </div>

      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '100px', justifyContent: 'center' }}>
          <CircularProgress size={65} />
        </Box>
      ) : (
        <>
          {isResponseShown && (
            <div className={styles.SuppliersOverlay}>
              <div className={styles.SuppliersResponseBox}>
                <div
                  style={{
                    fontSize: 16,
                    fontFamily: 'PT Sans',
                    textAlign: 'center',
                    margin: '20px',
                    marginLeft: '25px',
                    marginRight: '25px'
                  }}
                  dangerouslySetInnerHTML={{ __html: apiResponse }}
                >
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button className={styles.SuppliersButton}
                    onClick={() => setIsResponseShown(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}

          {modalIsOpen && editType == "ARCHIVE" && (
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <div style={{ marginLeft: '24px', marginRight: '24px', fontSize: '14px', fontFamily: 'PT Sans', textAlign: 'center', marginTop: '25px' }}>
                Are you sure you want to {text} this supplier?
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button className={buttonStyles.YesButtonComponentStyling} style={{ marginRight: '10px', marginBottom: '24px', marginTop: '19px' }} onClick={handleClick}>YES</button>
                <button className={buttonStyles.NoButtonComponentStyling} style={{ marginBottom: '24px', marginTop: '19px' }} onClick={closeModal}>NO</button>
              </div>
            </Modal>
          )}

          <Table data={fetchedData.update} handleRowEdit={handleRowEdit} handleRowArchive={openModal} />

          {/* {allRows.length === 0 && <div className='NoProjects'>THE RECORD YOU HAVE SEARCHED CANNOT BE FOUND</div>} */}
          {displayEditDiv && <EditDiv data={currentData} handleUpdateClick={handleUpdateClick} setDisplayEditDiv={setDisplayEditDiv} actionType={editType} />}

        </>
      )}

    </div>
  )

}

export default Suppliers;