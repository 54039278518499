import { createSlice } from "@reduxjs/toolkit";

// Redux

// ----------------------------------------------------------------------

let  initialState = {
  searchValue: "",
  searchInputValue: "",
  serviceFilter: "All",
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {

    // set filter values in redux
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },

    setServiceFilter(state, action) {
      state.serviceFilter = action.payload;
    },

    setSearchInputValue(state, action) {
      state.searchInputValue = action.payload;
    },

  },

});

export const {
  setSearchValue,
  setServiceFilter,
  setSearchInputValue,
} = suppliersSlice.actions;

// Reducer

export default suppliersSlice.reducer;