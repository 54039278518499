import React, { useState, useEffect } from "react";
import styles from "../Table/TableV2.module.css";
import Tick from "../../assets/Tick.svg";

const BIDTracker = ({ jsonData, headerMap = {}, clickableRowAdd = false }) => {
  // Default header names mapping
  const defaultHeaderMap = {
    jobNumber: "Job No",
    projectAddress: "Project Address",
    client: "Client",
    comment: "Comments",
    quoted: "Quoted",
    enquiryDate: "Created Date",
    followUpDate: "Follow-Up Date",
    isaSent: "ISA Sent",
    fpSentAt: "FP Sent",
    documentUploaded: "Docs",
  };

  // Merge the provided headerMap with defaultHeaderMap for custom header names
  const resolvedHeaderMap = { ...defaultHeaderMap, ...headerMap };

  // Extract headers in the order defined in defaultHeaderMap
  const headers = Object.keys(defaultHeaderMap).map(
    (key) => resolvedHeaderMap[key] || key
  );

  // Initially convert JSON array to rows format
  const initialRows = jsonData?.data?.map(
    (item) =>
      Object.keys(defaultHeaderMap) // Use the order from defaultHeaderMap
        .map((key) => item[key]?.toString() || "") // Convert each value to string, or empty string if undefined
  );
  const [rows, setRows] = useState(initialRows);

  // Pagination state and constants
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Set rows per page as desired

  // Calculate the rows to display based on the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = rows?.slice(indexOfFirstRow, indexOfLastRow);

  // Pagination functions
  const nextPage = () => setCurrentPage((prev) => prev + 1);
  const prevPage = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  useEffect(() => {
    const newRows = jsonData?.data?.map(
      (item) =>
        Object.keys(defaultHeaderMap) // Ensure the order is maintained
          .map((key) => item[key]?.toString() || "") // Convert to string or ''
    );
    setRows(newRows);
  }, [jsonData]); // Only re-run the effect if jsonData changes

  const sortRows = (index) => {
    let key = index;
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    // Adjusted from [...data.rows] to directly use rows derived from jsonData
    const sortedRows = [...rows].sort((a, b) => {
      let valA = a[index];
      let valB = b[index];
      const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const dateRegex2 = /^(\d{2})\/(\d{2})\/(\d{2})$/;
      if (valA === null || valB === null) return 0; // Skip null values

      // If value is a money string, ignore the first character and remove commas
      if (typeof valA === "string" && valA.charAt(0) === "£") {
        valA = parseFloat(valA.substring(1).replace(/,/g, ""));
        valB = parseFloat(valB.substring(1).replace(/,/g, ""));
      }
      // If the string starts with a number, isolate the number
      else if (
        typeof valA === "string" &&
        !isNaN(valA.charAt(0)) &&
        !dateRegex.test(valA) && 
        !dateRegex2.test(valA)
      ) {
        console.log("being called");
        const matchA = valA.match(/\d+/);
        const matchB = valB.match(/\d+/);
        if (matchA && matchB) {
          valA = parseFloat(matchA[0]);
          valB = parseFloat(matchB[0]);
        }
      }

      // If the string is a date in dd/mm/yy format
      else if (
        typeof valA === "string" &&
        dateRegex2.test(valA)
      ) {
        console.log("in date format");
        const [dayA, monthA, yearA] = valA.split("/").map(Number);
        const [dayB, monthB, yearB] = valB.split("/").map(Number);
        valA = new Date(yearA, monthA - 1, dayA).getTime();
        valB = new Date(yearB, monthB - 1, dayB).getTime();
      }
      // If the string is a date in dd/mm/yyyy format
      else if (typeof valA == "string" && dateRegex.test(valA)) {
        console.log("indateformat");
        const [dayA, monthA, yearA] = valA.split("/").map(Number);
        const [dayB, monthB, yearB] = valB.split("/").map(Number);
        valA = new Date(yearA, monthA - 1, dayA).getTime();
        valB = new Date(yearB, monthB - 1, dayB).getTime();
      }

      if (valA < valB) return direction === "ascending" ? -1 : 1;
      if (valA > valB) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setRows(sortedRows);
  };

  const handleRowClick = (firstColumnValue) => {
    if (typeof clickableRowAdd === "string") {
      window.location.href = `${clickableRowAdd}#${firstColumnValue}`;
    }
  };

  const rowClasses = `${styles.tableRow} ${
    clickableRowAdd ? styles.rowHoverHighlight : ""
  }`;

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            {headers?.map((header, index) => {
              // Determine the sorting arrow
              let arrow = "";
              if (sortConfig.key === index) {
                arrow = sortConfig.direction === "ascending" ? " ▲" : " ▼";
              }

              if (
                index == 0 ||
                index == 4 ||
                index == 5 ||
                index == 6 ||
                index == 7 ||
                index == 8 ||
                index == 9
              ) {
                return (
                  <th key={index} className={styles.th}>
                    <div
                      className={styles.CenterContent}
                      onClick={() => sortRows(index)}
                    >
                      {header}
                      {arrow}
                    </div>
                  </th>
                );
              }

              return (
                <th key={index} className={styles.th}>
                  <div onClick={() => sortRows(index)}>
                    {header}
                    {arrow}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {
          rows?.length === 0 ? // If no opportunities exist in the database
            (
              <tr>
                <td colSpan={7} className={styles.tdNoInfo}>No information on record.</td>
              </tr>
            )
          :
          rows?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowClasses}
              onClick={() => clickableRowAdd && handleRowClick(row[0])}
            >
              {row?.map((cell, cellIndex) => {
                if (cellIndex == 0) {
                  return (
                    <td
                      key={cellIndex}
                      style={{ fontFamily: "'PT Sans', sans-serif" }}
                      className={styles.td}
                    >
                      <div className={styles.CenterContent}>{cell}</div>
                    </td>
                  );
                }

                if (cellIndex == 4) {
                  return (
                    <td
                      key={cellIndex}
                      style={{ fontFamily: "'PT Sans', sans-serif" }}
                      className={styles.td}
                    >
                      <div className={styles.CenterContent}>
                        {typeof cell === "number"
                          ? parseInt(cell).toString()
                          : cell}
                      </div>
                    </td>
                  );
                } else if (
                  cellIndex === 5 ||
                  cellIndex === 6 ||
                  cellIndex === 8
                ) {
                  let formattedDate = cell; // Default to the original cell content

                  // Check if the cell content is a valid date string
                  if (Date.parse(cell)) {
                    const date = new Date(cell);
                    // Extracting day, month, and year from the date
                    const day = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0"); // Ensure two digits
                    const month = date.getDate().toString().padStart(2, "0"); // January is 0!
                    const year = date.getFullYear().toString().slice(-2); // Last two digits of the year

                    // Formatting the date as "dd/mm/yy"
                    formattedDate = `${day}/${month}/${year}`;
                  }

                  return (
                    <td key={cellIndex} className={styles.td}>
                      <div className={styles.CenterContent}>
                        {formattedDate}
                      </div>
                    </td>
                  );
                } else if (cellIndex == 7 || cellIndex == 9) {
                  return (
                    <td key={cellIndex} className={styles.td}>
                      <div className={styles.CenterContent}>
                        {cell === "true" ? <img src={Tick} alt="Tick" /> : null}
                      </div>
                    </td>
                  );
                }

                return (
                  <td key={cellIndex} className={styles.td}>
                    <div className="ellipsis-div">{cell}</div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BIDTracker;
