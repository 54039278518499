import React, { useState, useEffect } from "react";
import styles from "../EditDiv/EditDiv.module.css";
import buttonStyles from "../YesNoButtonsCSS/YesNoButtons.module.css";
import BlueLogo from "../../assets/BlueLogo.png";
import { Box, Grid, TextField, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import CommentBox from "../CommentBox/CommentBox";
import Dropdown from "../DropDownBox/Dropdown";
import DropDownV2 from "../DropDownBox/DropdownV2";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import textfeildStyle from "../../GlobalStyles/styles";

const ClientForm = ({ Name, onSubmit, onClose }) => {
  const { runners } = useSelector((state) => state.opportunities);

  // Make a copy of runners and add N/A at the beginning
  const modifiedRunners = [{ label: "N/A", value: 999 }, ...runners];

  const handleFormClick = (e) => {
    e.stopPropagation(); // Stops the click event from bubbling up to the parent div
  };

  const initialValues = {
    company: Name,
    runnerName: "",
    runnerID:"",
    phoneNumber: "",
    addressOne: "",
    addressTwo: "",
    town: "",
    city: "",
    postcode: "",
    comment: "",
    email: "",
    clientTypes: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: newClientSchema,
    onSubmit: async (values, { resetForm }) => {
      onSubmit(values);
      onClose();
      console.log(values);
    },
  });
  const { getFieldProps, touched, errors, handleSubmit } = formik;

  return (
    <div
      className={styles.backGround}
      style={{ position: "fixed", alignItems: "flex-start" }}
    >
      <div
        className={styles.form}
        style={{ width: "500px" }}
        onClick={handleFormClick}
      >
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>New Client</div>

        <FormikProvider value={formik} handleSubmit={handleSubmit}>
          <Form autoComplete="off" noValidate style={{ width: "100%" }}>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Company: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.company}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("company", e?.target?.value)
                  }
                  placeholder="Company"
                  sx={textfeildStyle}
                  error={Boolean(touched.company && errors.company)}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Preffered Runner:{" "}
                </p>

                {/* </div> */}
              </Grid>
              <Grid item xs={9} sx={{ mt: 1.5 }}>
                <DropDownV2
                  options={modifiedRunners}
                  initialSelectedValue={formik.values.runnerName}
                  placeholder={"Select Preffered Runner"}
                  onValueChange={(newValue) => {
                    formik.setFieldValue("runnerName", newValue.label);
                    formik.setFieldValue("runnerID", newValue.value);
                  }}
                  errorState={Boolean(
                    touched.runnerName && errors.runnerName
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Email Address: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.email}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("email", e?.target?.value)
                  }
                  placeholder="Email Address"
                  sx={textfeildStyle}
                  error={Boolean(touched.email && errors.email)}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Phone No: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.phoneNumber}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("phoneNumber", e?.target?.value)
                  }
                  placeholder="Phone Number"
                  sx={textfeildStyle}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Address 1: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.addressOne}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("addressOne", e?.target?.value)
                  }
                  placeholder="Address 1"
                  sx={textfeildStyle}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Address 2: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.addressTwo}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("addressTwo", e?.target?.value)
                  }
                  placeholder="Address 2"
                  sx={textfeildStyle}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Town: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.town}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("town", e?.target?.value)
                  }
                  placeholder="Town"
                  sx={textfeildStyle}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>City: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.city}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("city", e?.target?.value)
                  }
                  placeholder="City"
                  sx={textfeildStyle}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2}>Post Code: </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.postcode}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("postcode", e?.target?.value)
                  }
                  placeholder="Post Code"
                  sx={textfeildStyle}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Client Type:{" "}
                </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1.5 }}>
                <Dropdown
                  options={["Professional", "Home Owner"]}
                  initialSelectedValue={formik.values.clientTypes}
                  placeholder={"Select Client Types"}
                  onValueChange={(newValue) =>
                    formik.setFieldValue("clientTypes", newValue)
                  }
                  PopUp={true}
                  errorState={Boolean(
                    touched.clientTypes && errors.clientTypes
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={3} sx={{ pr: 3 }}>
                <p className={styles.dataTitleV2} style={{ pb: 0, mb: 0 }}>
                  Comments:{" "}
                </p>
              </Grid>
              <Grid item xs={9} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  value={formik.values.comment}
                  type="number"
                  onChange={(e) =>
                    formik.setFieldValue("comment", e?.target?.value)
                  }
                  rows={2}
                  placeholder="Comment"
                  sx={textfeildStyle}
                  //   textareaStyle={textfeildStyle}
                  inputProps={{ style: { margin: 0 } }}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>

        {/* <ul className={styles.List}>{formFieldsConfig.map(renderField)}</ul> */}

        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={handleSubmit}>
            <div className={buttonStyles.YesButtonComponentStyling}>SUBMIT</div>
          </div>
          <div className={styles.cancelButton} onClick={onClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
      </div>
    </div>
  );
};

const newClientSchema = Yup.object().shape({
  company: Yup.string().required(),
  runnerName: Yup.string().required(),
  email: Yup.string().required(),
  clientTypes: Yup.string().required(),
});

export default ClientForm;
