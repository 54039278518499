import React, { useEffect, useState, useContext } from 'react';
import '../../ptSansFontFamily.css';
import '../../App.css';
import styles from './Sidebarlist.module.css';
import topAndBottomStyles from './SidebarTopAndBottom.module.css';
import { SidebarData } from './SidebarData';
import BlueLogo from '../../assets/BlueLogo.png';
import { Link, useLocation } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';
import AdminContext from '../../Context/adminContext';

import { SignOutButton } from "../Auth/SignOutButton";

/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */

function StaffRole() {
  const [data, setData] = useState(null);
  const { accounts } = useMsal();
  const account = accounts[0]; // If multiple accounts, select the one you want to use

useEffect(() => {
  const API_URL = process.env.REACT_APP_API_URL;
    if (account) {
      fetch(`${API_URL}/api/SidebarProfileTitle?email=${account.username}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      })
      .then(response => response.json())
      .then(data => setData(data.message));
    }
}, [account]);

  return (
      <div className={topAndBottomStyles.profileRole}>
        {data && <div>{data}</div>}
      </div>

  );
}

function SidebarItem({ val }) {
  const { pathname } = useLocation();
  const isActive = pathname === val.link || pathname.startsWith(val.link + '/');
  const isDisabled = val.disabled;

  return (
    <React.Fragment>
      <li>
        <Link
          to={val.link}
          className={`${styles.row} ${isActive ? styles.ClickedOn : ''} ${isDisabled ? styles.disabled : styles.active}`}
          id={isActive ? styles.active : ''}
          onClick={(event) => {
            if (isDisabled) {
              event.preventDefault();
            }
          }}
        >
          <div
            id="icon"
            className={`${styles.iconSpace} ${isActive ? styles.iconClickedOn : ''} ${isDisabled ? styles.iconDisabled : ''}`}
          >
            {val.icon}
          </div>
          <div id="title" className={`${styles.listText} ${isActive ? styles.active : ''} ${isDisabled ? styles.disabled : ''}`}>
            {val.title}
          </div>
        </Link>
      </li>
      {val.title === 'Resourcing' && <div className={styles.dottedLine}></div>}
    </React.Fragment>
  );
}

function Sidebar() {
  const { instance, accounts } = useMsal();
  const account = accounts[0]; // If multiple accounts, select the one you want to use
  const [image, setImage] = useState(null);
  // const [isAdmin, setIsAdmin] = useState(false);
  const isAdmin = useContext(AdminContext);
  const API_URL = process.env.REACT_APP_API_URL;

  // useEffect(() => {
  //   if (account) {
  //     fetch(`${API_URL}/api/checkIfAdmin?email=${account.username}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //       .then(response => response.json())
  //       .then(data => setIsAdmin(data.message));
  //   }
  // }, [account]);

  const [reviewer, setReviewer] = useState(null);
  useEffect(() => {
    if (account) {
    fetch(`${API_URL}/api/checkIfReviewer?email=${account.username}`, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then(data => setReviewer(data.message));
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response) => {
          // Fetch the image from the Graph API
          fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: {
              'Authorization': `Bearer ${response.accessToken}`,
            },
          })
          .then((response) => response.blob())
          .then((blob) => {
            // Convert the image blob to a data URL and store it in state
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
              let base64data = reader.result;
              setImage(base64data);
            }
          })
          .catch((error) => console.error(error));
        });
    }
  }, [account, instance]);

  return (
    <div className={styles.Sidebar}>

      <div className={topAndBottomStyles.profileContainer}>
        {image ? <img src={image} alt="Profile" className={topAndBottomStyles.profileIcon} /> : null}
        <div className={topAndBottomStyles.profileInfo}>
          <div className={topAndBottomStyles.profilenName}>{account ? account.name : ''}</div>
          <StaffRole />
        </div>
      </div>
      
      <ul className={styles.SidebarList}>
        {SidebarData.map((val, key) => (
          (val.title !== 'Admin' || (val.title === 'Admin' && isAdmin)) && 
          <SidebarItem key={key} val={val} />
        ))}
      </ul>

      <div className={topAndBottomStyles.SidebarBottom}>
        <SignOutButton />
        <div className={topAndBottomStyles.BlueLogoContainer}><img src={BlueLogo} className={topAndBottomStyles.blueLogo}/></div>
      </div>

    </div>
  );
}

export default Sidebar;
