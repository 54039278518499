import React, { useState, useEffect } from "react";
import styles from "../../Components/Table/TableV2.module.css";
import Tick from "../../assets/Tick.svg";
import moment from "moment";

const InvoiceTable = ({ jsonData, headerMap = {}, clickableRowAdd = true }) => {
  // Merge the provided headerMap with defaultHeaderMap for custom header names
  const resolvedHeaderMap = headerMap;
  console.log(resolvedHeaderMap, "resolvedHeaderMap");
  // Extract headers in the order defined in defaultHeaderMap
  const headers = Object.keys(resolvedHeaderMap).map(
    (key) => resolvedHeaderMap[key] || key
  );

  // Initially convert JSON array to rows format
  const initialRows = jsonData?.data?.map(
    (item) =>
      Object.keys(resolvedHeaderMap) // Use the order from defaultHeaderMap
        .map((key) => item[key]?.toString() || "") // Convert each value to string, or empty string if undefined
  );
  console.log(initialRows, "test");
  const [rows, setRows] = useState(initialRows);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  useEffect(() => {
    const newRows = jsonData?.data?.map(
      (item) =>
        Object.keys(resolvedHeaderMap) // Ensure the order is maintained
          .map((key) => item[key]?.toString() || "") // Convert to string or ''
    );
    setRows(newRows);
  }, [jsonData]); // Only re-run the effect if jsonData changes

  const sortRows = (index) => {
    let key = index;
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    // Adjusted from [...data.rows] to directly use rows derived from jsonData
    const sortedRows = [...rows].sort((a, b) => {
      let valA = a[index];
      let valB = b[index];

      if (valA === null || valB === null) return 0; // Skip null values

      // If value is a money string, ignore the first character and remove commas
      if (typeof valA === "string" && valA.charAt(0) === "£") {
        valA = parseFloat(valA.substring(1).replace(/,/g, ""));
        valB = parseFloat(valB.substring(1).replace(/,/g, ""));
      }
      // If the string starts with a number, isolate the number
      else if (typeof valA === "string" && !isNaN(valA.charAt(0))) {
        const matchA = valA.match(/\d+/);
        const matchB = valB.match(/\d+/);
        if (matchA && matchB) {
          valA = parseFloat(matchA[0]);
          valB = parseFloat(matchB[0]);
        }
      }
      // If the string is a date in dd/mm/yy format
      else if (
        typeof valA === "string" &&
        /^(\d{2})\/(\d{2})\/(\d{2})$/.test(valA)
      ) {
        const [dayA, monthA, yearA] = valA.split("/").map(Number);
        const [dayB, monthB, yearB] = valB.split("/").map(Number);
        valA = new Date(yearA, monthA - 1, dayA).getTime();
        valB = new Date(yearB, monthB - 1, dayB).getTime();
      }

      if (valA < valB) return direction === "ascending" ? -1 : 1;
      if (valA > valB) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setRows(sortedRows);
  };

  const handleRowClick = (row) => {
    if (row[0] && row[11] && row[4]) {
      window.location.href = `${"raise-invoice"}?jobNumber=${
        row[0]
      }&invoiceNumber=${row[11]}&status=${row[4]}`;
    }
  };

  const rowClasses = `${styles.tableRow} ${
    clickableRowAdd ? styles.rowHoverHighlight : ""
  }`;

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            {headers?.map((header, index) => {
              // Determine the sorting arrow
              let arrow = "";
              if (sortConfig.key === index) {
                arrow = sortConfig.direction === "ascending" ? " ▲" : " ▼";
              }

              if (
                index == 0 ||
                index == 4 ||
                index == 5 ||
                index == 6 ||
                index == 7 ||
                index == 8 ||
                index == 9
              ) {
                return (
                  <th key={index} className={styles.th}>
                    <div
                      className={styles.CenterContent}
                      onClick={() => sortRows(index)}
                    >
                      {header}
                      {arrow}
                    </div>
                  </th>
                );
              }

              return (
                <th key={index} className={styles.th}>
                  <div onClick={() => sortRows(index)}>
                    {header}
                    {arrow}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {rows?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowClasses}
              onClick={() => clickableRowAdd && handleRowClick(row)}
            >
              {row?.map((cell, cellIndex) => {
                if (cellIndex == 0) {
                  return (
                    <td
                      key={cellIndex}
                      style={{ fontFamily: "'PT Sans', sans-serif" }}
                      className={styles.td}
                    >
                      <div className={styles.CenterContent}>{cell}</div>
                    </td>
                  );
                }
                if (cellIndex == 4 || cellIndex == 5) {
                  return (
                    <td
                      key={cellIndex}
                      style={{ fontFamily: "'PT Sans', sans-serif" }}
                      className={styles.td}
                    >
                      <div className={styles.CenterContent}>{cell.toUpperCase()}</div>
                    </td>
                  );
                }
                if (cellIndex == 6 || cellIndex == 8) {
                  return (
                    <td
                      key={cellIndex}
                      style={{ fontFamily: "'PT Sans', sans-serif" }}
                      className={styles.td}
                    >
                      <div className={styles.CenterContent}>
                        {parseFloat(cell).toLocaleString("en-UK", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0,
                        })}
                      </div>
                    </td>
                  );
                }
                if (cellIndex == 7 || cellIndex == 9 || cellIndex == 10) {
                  return (
                    <td
                      key={cellIndex}
                      style={{ fontFamily: "'PT Sans', sans-serif" }}
                      className={styles.td}
                    >
                      <div className={styles.CenterContent}>
                        {cell
                          ? moment(cell, "YYYY-MM-DD HH:mm:ss").format(
                              "DD-MMM-YY"
                            )
                          : ""}
                      </div>
                    </td>
                  );
                }

                //  if (cellIndex === 5 || cellIndex === 7 || cellIndex === 9) {
                //     let formattedDate = cell;  // Default to the original cell content

                //     // Check if the cell content is a valid date string
                //     if (Date.parse(cell)) {
                //         const date = new Date(cell);

                //         const day = String(date.getDate()).padStart(2, '0');
                //         const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
                //         const year = date.getFullYear().toString().substr(-2); // Get the last two digits of the year

                //         formattedDate = `${day}/${month}/${year}`;
                //     }

                //     return (
                //         <td key={cellIndex} className={styles.td}>
                //             <div className={styles.CenterContent}>
                //                 {formattedDate}
                //             </div>
                //         </td>
                //     );
                // }

                if (cellIndex == 12) {
                  return (
                    <td key={cellIndex} className={styles.td}>
                      <div className={styles.CenterContent}>
                        {cell === "true" ? <img src={Tick} alt="Tick" /> : null}
                      </div>
                    </td>
                  );
                }

                return (
                  <td key={cellIndex} className={styles.td}>
                    <div className="ellipsis-div">{cell}</div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTable;
